


<div class="bg"  *ngIf="!loading">
  <ng-container *ngFor=" let slider of sliderScelet; let i = index">
   
    <div class="slide" [id]="slider.title.LV">
      <img  *ngIf="!scrolled[slider.title.LV]"  height="100px"  src="assets/img/loading.gif" alt="">

      <app-slider *ngIf="scrolled[slider.title.LV] && showAmount>i" [data]="{title:slider.title, type:slider.type,filterOptions:slider.filterOptions,socLinks:slider.socLinks}" ></app-slider>
    </div>
    
  </ng-container>

  <ng-container  *ngIf="showAmount<sliderScelet.length">
    <div class="divader"></div>
    <div class="butt link" (click)="doSomething('showMore')">{{('slider.loadMore'| translate)}}</div>
  </ng-container>
  
  
</div>




