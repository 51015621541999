  <!-- <div class="head">
    <app-headmenu></app-headmenu>

  </div> -->
<ng-container *ngIf="page && page.title">
 

    <div class="fullPage">
      <div *ngIf="false" class="imgbg" [style.background-image]=" 'url('+api+'filesystem/getfile/'+page.image +')'   | safe :'style'" >
        <div class="imgText">
            {{page.title[language]}}
        </div>
      </div>

      <div class="imgbg newVersion" >
        <img class="imgFull" [src]="api+'filesystem/getfile/'+page.image+'/1920x500'" alt="" srcset="">
        <div class="imgText">
          {{page.title[language]}}
      </div>
      </div>

     
 
    <!-- raksta texta daļa -->
      <ng-container  >
        <app-agent [data]="{agent:this.page.signedUser, widget:true}" ></app-agent>

        <!-- Raksts body -->
        <section class="raksts-body" *ngIf="true">
          <div class="">
              <div class="row blog dib">
                  <div class="col-12" [innerHTML]="page.desc[language] | safe:'html'" > </div>
              </div>

              <!-- blog sidebar list start-->
              <div *ngIf="pageId!=88" class="otherBlogs dib">
                <div [routerLink]="'/'+blogPage['seo']['url'][language]" (click)="changePage()" class="blogPage link" *ngFor="let blogPage of pages; let i = index;">
                  <ng-container *ngIf="i<3">
                    <div  class="blogImg" [style.background-image]=" 'url('+api+'filesystem/getfile/'+blogPage.image +'/335x120)'   | safe :'style'" >
                    </div>
                    <div class="sortDesc">{{blogPage.title[language]}}</div>
                    <div class="Readmore link">{{'slider.open' | translate}}</div>
                  </ng-container>
                
    
                </div>
              </div>
              <!-- blog sidebar list end -->
          </div>
      </section>
      </ng-container>
      
      <div class="contactForm" *ngIf="pageId==88">
        <app-contact-form [limited]="true" ></app-contact-form>
      </div>
  
    </div>

</ng-container>

<ng-container *ngIf="allPages">
  <section class="raksts-body" >
    <div class="allBlogs">
      <ng-container  *ngFor="let blogPage of pages; let i = index;">
        <div *ngIf="blogPage.linked==linked" [routerLink]="'/'+blogPage['seo']['url'][language]" class="blogPage2 link dib">
          <div  class="blogImg2 dib" style="width: 200px;" [style.background-image]=" 'url('+api+'filesystem/getfile/'+blogPage.image +'/medium)'   | safe :'style'" >
          </div>
          <div class="apr dib">
            <div class="sortDesc2 " [innerHTML]="blogPage.title[language].substr(0,45) + (blogPage.title[language].length>45?'...':'')"></div>
            <div class="sortDesc3 " [innerHTML]="blogPage.seo.shortDesc[language].substr(0,100) + (blogPage.seo.shortDesc[language].length>100?'...':'')" ></div>
            <div class="time">{{blogPage.timestamp | date:'dd-MM-yyyy'}}</div>
            <div class="Readmore2 link">{{'slider.open' | translate}}</div>
          </div>
          
        </div>
      </ng-container>
   
    </div>
  </section>
  
</ng-container>

<app-slider *ngIf="false && slider && slider[6] && pageId==37" [data]="{title:slider[6].title, type:slider[6].type,filterOptions:slider[6].filterOptions,socLinks:slider[6].socLinks,hideTeams:true}" ></app-slider>



 





