import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../global.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public cookieNotification=this.global.cookieNotification
  
  constructor(private global:GlobalService) { }

  ngOnInit() {
    setTimeout(()=>{
      this.cookieNotification=this.global.cookieNotification
    },2000)
    
  }

  saveCookie(){
    this.global.CookieService("SET",{name:"token",value:1})
    this.cookieNotification=false
  }
}
