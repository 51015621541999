<!-----Product---->

<section>
    <div class="products">
        <div class="asasas" *ngIf="(data.type=='object'&& productList.length>0)|| (data.type=='blogPosts' && postList && postList.length>0) || ['object','blogPosts'].indexOf(data.type)==-1 ">
            <div class="yellow-lable text-center text-lg-right text-white">
                <h4 class="m-auto text-uppercase">{{data.title[language] ? data.title[language] : data.title }}  </h4>
                <hr class="blue-line">
                <span class="icone-s" *ngIf="data.socLinks">



                    <a class="dib" target="#" href="https://www.facebook.com/immostate.lv">
                        <img src="assets/social/021-facebook-orange.svg" class="s-icon">
                    </a>
                    <a class="dib" target="#" href="https://www.instagram.com/immostate_realty/">
                        <img src="assets/social/025-instagram-orange.svg" class="s-icon">
                    </a>
                    <a class="dib" target="#" href="https://www.linkedin.com/company/immostate">
                        <img src="assets/social/045-linkedin-orange.svg" class="s-icon">
                    </a>
                </span>
            </div>
            <div class="container pr">

                <hr class="gray-line link">
                <ng-container *ngIf="!data.hideShowMore">
                    <span (click)='doSomething("toTop")' *ngIf="data.type=='blogPosts'" class="text-fix link"
                        [routerLink]="['/pages']" [queryParams]="{linked:data.title[language] }">
                        {{('slider.seeAll'| translate)}}
                        <div class="DownIcon"></div>
                    </span>
                    <span (click)='doSomething("toTop");doSomething("OpenProdList")'
                        *ngIf="data.type!='contacts' && data.type!='blogPosts' && data.type!='services' && data.type!='contactForm' "
                        class="text-fix link">
                        {{('slider.seeAll'| translate)}}
                        <div class="DownIcon"></div>
                    </span>
                    <span (click)='doSomething("toTop")' *ngIf="data.type=='services'" class="text-fix link"
                        [routerLink]="['/services']">
                        {{('slider.seeAll'| translate)}}
                        <div class="DownIcon"></div>
                    </span>

                    <span *ngIf="data.type=='contacts'" class="text-fix link"
                        (click)="!expanded ? expanded=true:expanded=false">
                        {{!expanded ?('slider.seeAll'| translate):('slider.hide'| translate)}}
                        <div [style.transform]="expanded? 'rotate(180deg)': '' " class="DownIcon"></div>
                    </span>
                </ng-container>


            </div>
        </div>

        <ng-container *ngIf="data.type=='object'">
            <div class=" ">
                <swiper *ngIf="productList && productList.length>0" [config]="config" class="swiper-container"
                    #usefulSwiper>
                    <div class="swiper-wrapper">
                        <div class="swiper-slide" *ngFor="let slide of productList;">


                            <div class="card link" [routerLink]="'/object/'+slide.id">
                                <div class="img-card">
                                    <hr class="blue-line-1">
                                    <div class="img"
                                        [style.background-image]="'url('+api+'filesystem/getfileFromURL/'+encodeURIComponent(slide['img'])+'/small2/cover)' | safe:'style'">
                                    </div>
                                    <div class="prize top"
                                        *ngIf="false && ( slide.type.transaction=='rent' || slide.type.transaction=='lease')">
                                        <h6 style="font-size: 0.7rem;">
                                            {{'header.'+(slide.type.transaction) | translate}}
                                        </h6>
                                        <hr class="yellow-lines">
                                    </div>
                                    <div class="prize">
                                        <h6> {{slide.hierarchy.type=='parent' && slide.parrentData.lowestChildPrice ? 'Sākot no:' : ''}}
                                            {{slide.hierarchy.type=='parent' && slide.parrentData && slide.parrentData.lowestChildPrice ? slide.parrentData.lowestChildPrice +' EUR': '€ '+slide['price']}}
                                        </h6>
                                        <hr class="yellow-lines">
                                    </div>

                                </div>
                                <div style="padding-top: 15px;">
                                    <h5 class="kk">
                                        <div class="dib ma" *ngIf="slide.location.levels[2]">
                                            {{slide.location.levels[2].toUpperCase() +', '}}</div>
                                        <div class="dib ma" *ngIf="slide.location.street">
                                            {{' '+(slide.location.street.length<20? slide.location.street.toUpperCase():(slide.location.street.toUpperCase()).substr(0,20)+'...')}}
                                        </div>
                                    </h5>

                                    <div class="slideObjCardText">
                                        <!-- <span *ngIf="slide.size.areasize>0 || slide.size.landsize>0 ">
                                            {{slide.size.areasize>0 ? slide.size.areasize :slide.size.landsize}}
                                            M<sup>2</sup>
                                        </span>
                                        <span *ngIf="slide.otherJSON.numberOfRooms">
                                            <span class="vertLine">|</span>
                                            {{ slide.otherJSON.numberOfRooms}}
                                            {{slide.otherJSON.numberOfRooms<1? translations.numberOfRooms[language] : translations.numberOfRooms2[language] }}

                                        </span>
                                        <span *ngIf="slide.floor.amount>0 && slide.floor.current ">
                                            <span class="vertLine">|</span>
                                            {{slide.floor.current+'.'+translations.floor[language]}}
                                            ({{ slide.floor.current +'/'+slide.floor.amount}})
                                        </span>
                                        <span *ngIf="slide.type.pricem2 && false">
                                            <span class="vertLine">|</span>
                                           
                                            {{ slide.type.pricem2.toFixed(0)}}<sup>€/M<sup>2</sup></sup> 
                                        </span> -->

                                        <!-- only landingSize and areaSize M2 -->
                                        <div class="pars hideScrollbar" style="max-height: 45px;overflow: auto;max-width: 275px;">
                                            <ng-container *ngFor="let param of Object.keys(slide['size']) ; let i = index;">
                                                <span class=" params"
                                                    *ngIf=" slide['size'][param]>0 && ('params.'+param | translate) !=''">
                                                    <span class="paramName one">
                                                        {{Number(slide['size'][param]).toFixed(0)}}
    
                                                    </span>
    
                                                    <span class="paramName two">
                                                        <ng-container *ngIf="param=='areasize'">
                                                            M<sup>2</sup>
                                                        </ng-container>
                                                        <ng-container *ngIf="param=='landsize'">
                                                            <span
                                                                [innerHTML]="'params.landSize' | translate">
                                                            </span>
                                                        </ng-container>
                                                    </span>
                                                    <span
                                                        *ngIf="(Object.keys(slide['size']).length>1 && i==0) || Object.keys(slide.smallParams).length>0"
                                                        class="vertLine">|</span>
                                                </span>
    
                                            </ng-container>
    
                                            <ng-container *ngIf="slide.params">
                                                <ng-container
                                                    *ngFor="let param of Object.keys(slide.smallParams) ; let i = index;"
                                                    class="allParams">
    
    
                                                    <ng-container>
                                                        <span class="paramName" *ngIf="slide['smallParams'][param] && slide['smallParams'][param]!==true ">
                                                            {{   (slide['smallParams'][param]!==true)?slide['smallParams'][param]:'' }}{{param=='floorNumber'?'.':''}}
                                                        </span>
    
                                                        <span class="paramName" [innerHTML]="'params.'+param | translate">
    
                                                        </span>
    
                                                        <span class="paramName" *ngIf="param=='floorNumber'">
                                                            ({{  slide['params'][param]+(slide['params'].numberOfFloors ? '/'+slide['params'].numberOfFloors :'') }})
                                                        </span>
                                                        <span *ngIf="i!=2 && i!=Object.keys(slide.smallParams).length-1"
                                                            class="vertLine">|</span>
                                                    </ng-container>
    
    
                                                </ng-container>
                                            </ng-container>
                                        </div>
                                        





                                        <!-- params -->





                                        <div class="lowerText hideScrollbar"  style="max-height: 45px;overflow: auto;max-width: 275px;"
                                            [innerHTML]="slide.name[langChanger[language]].toUpperCase() | safe:'html'"
                                            *ngIf="slide.name[langChanger[language]]"></div>
                                    </div>




                                    <div class="btn-1">
                                        <button class="blue-btn text-white dis-block"
                                            [routerLink]="'/object/'+slide.id">{{'slider.open' | translate}}</button>
                                    </div>
                                </div>
                            </div>


                        </div>

                    </div>
                    <!-- {{'calc('+slideHeight+'px*0.'+(this.config.slidesPerView.toString().split('.')[1])+')'}} -->
                    <div class="bgPagna right"
                        [style.width]="'calc('+slideHeight+'px*0.'+(this.config.slidesPerView.toString().split('.')[1])+')'"
                        *ngIf="navig.next">
                    </div>
                    <div class="bgPagna left" *ngIf="navig.back">
                    </div>
                    <div class="next link" (click)="doSomething('next')" *ngIf="navig.next || width<955">
                        <div class="nextIcon"></div>
                    </div>


                    <div class="back link" (click)="doSomething('back')" *ngIf="navig.back">
                        <div class="backIcon"></div>
                    </div>
                </swiper>
            </div>
        </ng-container>
        <ng-container *ngIf="data.type=='blogPosts'">

            <div class="blogPosts" *ngIf="postList && postList.length>0">
                <swiper  [config]="config" class="swiper-container" #usefulSwiper>
                    <div class="swiper-wrapper">
                        <div class="swiper-slide" *ngFor="let slide of postList;">

                            <div class="card" *ngIf="slide['title'][language]" (click)="window.scrollTo(0,0)" style="height: 100%;"
                                [routerLink]="'/'+slide['seo']['url'][language]">
                                <div class="img-card">
                                    <div class="img"
                                        [style.background-image]="'url('+api+'filesystem/getfile/'+slide['image']+'/small3)' | safe:'style'">
                                    </div>
                                </div>
                                <div class="py-3">
                                    <h5 class="kk" style="text-transform: uppercase;"
                                        [innerHTML]="slide['title'][language] | safe:'html'">
                                    </h5>

                                    <p style="font-size: 14px;"
                                        [innerHTML]="slide['seo']['shortDesc'][language] | safe:'html'">

                                    </p>

                                </div>
                            </div>


                        </div>

                    </div>
                    <div class="bgPagna right" *ngIf="navig.next">
                    </div>
                    <div class="bgPagna left" *ngIf="navig.back">
                    </div>
                    <div class="next link" (click)="doSomething('next')" *ngIf="navig.next || width<955">
                        <div class="nextIcon"></div>
                    </div>


                    <div class="back link" (click)="doSomething('back')" *ngIf="navig.back">
                        <div class="backIcon"></div>
                    </div>
                </swiper>
            </div>
        </ng-container>
        <ng-container *ngIf="data.type=='services'">
            <div class=" ">
                <swiper *ngIf="services && services.length>0" [config]="config" class="swiper-container" #usefulSwiper
                    style="height: 175px;">
                    <div class="swiper-wrapper">

                        <div class="swiper-slide" *ngFor="let slide of services;">

                            <div class="card  Services link" (click)="window.scrollTo(0,0)"
                                [routerLink]="'/services/'+slide.title[language]">
                                <div class="pakimg "
                                    [style.background-image]="'url('+api+'filesystem/getfile/'+slide.img+'/small3)' | safe:'style'">
                                    <div class="paktext" [innerHTML]="slide.title[language] | safe:'html'"></div>
                                </div>

                            </div>

                        </div>

                    </div>
                    <div class="bgPagna right" *ngIf="navig.next">
                    </div>
                    <div class="bgPagna left" *ngIf="navig.back">
                    </div>
                    <div class="next link" (click)="doSomething('next')" *ngIf="navig.next || width<955">
                        <div class="nextIcon"></div>
                    </div>


                    <div class="back link" (click)="doSomething('back')" *ngIf="navig.back">
                        <div class="backIcon"></div>
                    </div>
                </swiper>
            </div>
        </ng-container>
        <ng-container *ngIf="data.type=='contacts'">
            <div class="team" >
                <ng-container *ngFor="let agent of order(userlist) ;let i = index;">
                    <div [routerLink]="'agent/'+agent.id" (click)="window.scrollTo(0,0);reloadLists()" class="member dib link"
                        *ngIf="!expanded && i<2 || expanded">
                        <div class="agentimg dib"
                            [style.background-image]="'url('+api+'filesystem/getfile/'+agent.otherJSON.imgClose+'/large)' | safe:'style'">
                        </div>
                        <div class="info dib">
                            <div class="agentname">
                                {{agent.name+' '+agent.surname}}</div>
                            <div class="jobTitle">{{agent.otherJSON.desc[language]}}
                            </div>
                            <div class="contacts">
                                {{agent.otherJSON.soc[2].link}} <br>
                                {{agent.otherJSON.soc[3].link}}
                            </div>
                        </div>
                    </div>
                </ng-container>
                <div class="fadebottom" *ngIf="!expanded"></div>
                <div *ngIf="!expanded" (click)="expanded=true" [style.transform]="expanded? 'rotate(180deg)': '' "
                    class="DownIcon2 link"></div>
            </div>
        </ng-container>
        <ng-container *ngIf="data.type=='contactForm'">
            <app-contact-form></app-contact-form>
        </ng-container>
        <ng-container *ngIf="data.type=='vacances'">
            <div class=" ">
                <swiper *ngIf="vacances && vacances.length>0" [config]="config" class="swiper-container" #usefulSwiper
                    style="height: 175px;">
                    <div class="swiper-wrapper">

                        <div class="swiper-slide" *ngFor="let slide of vacances;">

                            <div class="card  Services link" (click)="window.scrollTo(0,0)"
                                [routerLink]="'/vacances/'+slide.title[language]">
                                <div class="pakimg "
                                    [style.background-image]="'url('+api+'filesystem/getfile/'+slide.img+'/small)' | safe:'style'">
                                    <div class="paktext" [innerHTML]="slide.title[language] | safe:'html'"></div>
                                </div>

                            </div>

                        </div>

                    </div>
                    <div class="bgPagna right" *ngIf="navig.next">
                    </div>
                    <div class="bgPagna left" *ngIf="navig.back">
                    </div>
                    <div class="next link" (click)="doSomething('next')" *ngIf="navig.next">
                        <div class="nextIcon"></div>
                    </div>


                    <div class="back link" (click)="doSomething('back')" *ngIf="navig.back">
                        <div class="backIcon"></div>
                    </div>
                </swiper>
            </div>
        </ng-container>


    </div>
</section>
<!---->