import { Component, OnInit, Input, ViewChild } from '@angular/core';
import Swiper, { SwiperModule,SwiperOptions } from 'swiper';
import { Angular2UsefulSwiperModule, SwiperComponent } from 'angular2-useful-swiper';
import { GlobalService } from 'src/app/global.service';
import { Subscription } from 'rxjs';
import { MessageService } from 'src/app/message.service';
import { Router } from '@angular/router';
import { filter } from 'src/app/interfaces';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {
  @ViewChild('usefulSwiper') usefulSwiper: SwiperComponent;
  
  @Input('data') public data: {
    filterOptions:filter,
    type:string,
    children:any,
    agentID:number,
    title:any,
    hideShowMore:false,
    socLinks:false,
    pageId:number,
    teamsTop:boolean
  };

  message: any;
  subscription: Subscription;
  
  public JSON=JSON;
 public Object=Object;
 public Number=Number;


 
 public window=window;
  public language=this.global.language
 public langChanger=this.global.langChanger
 public translations=this.global.translations

 public api=this.global.api

  public encodeURIComponent=encodeURIComponent

 public userlist= this.global.agents
 public postList=[]
 public services=[]
 public vacances=[]
 public expanded=true;

 public width=window.innerWidth
  public navig={
    next:true,
    back:false
  }
  public slideHeight=288
  config: SwiperOptions = {
  
    spaceBetween: 1,
    slidesPerView:(this.width * 0.8 )/ (this.slideHeight) ,
   // slidesPerView:4.5 ,
  };

  public counter=0;
  
  public productList=[]
  constructor(private global: GlobalService , private messageService:MessageService,private router:Router) { 

    this.global.languageChecker().then(()=>{
      this.language = this.global.language
    })



    this.subscription = this.messageService.getMessage().subscribe(message => {
      this.message = message;
      if (this.message['text'] == 'expandSlider') {
        this.expanded=true
      }
      if (this.message['text'] == 'UpdateLang') {
        this.language = this.message['element'];
        ////////console.log('maina', this.language)
      }
    });

    if(this.width<956){
      this.config.slidesPerView=1.2
    }
  }

  async ngOnInit() {
    ////console.log(this.config.slidesPerView)
    if(this.data.type=='object'){
      if(this.data.filterOptions ){
       // ////console.log('filtered search',this.data.filterOptions)
        this.getDataFiltered(this.data.filterOptions)
      }
       if(this.data.children){
         this.productList=this.global.transformProductList(this.data.children)
       }
      if(this.data.agentID){
        this.getData(null,this.data.agentID)
      }
    }
   
    if(this.data.type=='contacts'){
      if(this.global.agents.length<1){
        this.userlist=await this.global.getAgents()
      }
      
    }

    if(this.data.type=='blogPosts'){
     this.postList= await this.getPage(this.data.agentID)
    //  //////console.log( 'poslist',this.postList)
    }
    if(this.data.type=='services'){
     this.services= await this.global.HTTPReq('GET', this.global.api, 'filesystem/getfile/alighment/pakalpojumi.json')
    }
    if(this.data.type=='vacances'){
      this.vacances= await this.global.HTTPReq('GET', this.global.api, 'filesystem/getfile/alighment/vacances.json')
     }
     
     setTimeout(()=>{
      this.slidePagnateButtcheck()
     },1000)
  }

  doSomething(task,element?){
    if(task=='next'){
      this.usefulSwiper.swiper.slideNext();
    }
    if(task=='back'){
      this.usefulSwiper.swiper.slidePrev();
    }
    if(task=='addCount'){
      this.counter++
    ////////console.log(this.counter)
    }
    if(task=="toTop"){
      window.scrollTo(0,0)
    }

    if(task=="OpenProdList"){
      this.global.filterOptions=this.data.filterOptions
      let params:filter=this.JSON.parse(JSON.stringify(this.data.filterOptions))

      params.multiplier=100000
      this.router.navigate(['/products',{params:JSON.stringify(params)}])
     
                      
    }

    this.slidePagnateButtcheck()

  }



  async getData(keyWord?,agentID?) {
    let passdata={
      agentID,
      multiplier:30
    }
    if(keyWord){
      passdata['keyWord']=keyWord
    }
    await this.global.HTTPReq("POST", this.global.api, 'products/getProducts',passdata).then((res) => {    
      this.productList=this.global.transformProductList(res)
    })
    
  }

  async getDataFiltered(attr?) {
    attr["multiplier"]=10000
    await this.global.HTTPReq("POST", this.global.api, 'products/getProductsFiltered', attr).then((res) => {
      this.productList=this.global.transformProductList(res)
    })
  }

  
  async getPage(agentID?){
    let para = { type:2, multiplier:100,public:true,agentID,linked:this.data.title['LV']}
    this.postList= await this.global.HTTPReq('POST', this.global.api, 'pages/getPages', para)
    return this.postList
}

slidePagnateButtcheck(){
  if(this.usefulSwiper && this.usefulSwiper.swiper && this.usefulSwiper.swiper.slides){

  }else{
    return
  }
  if( this.usefulSwiper.swiper.slides.length<this.config.slidesPerView){
    this.navig.next=false
    return
  }else{
    this.navig.next=true
  }
  if(this.usefulSwiper.swiper.realIndex<1){
    this.navig.back=false
  }else{
    this.navig.back=true
  }
  if(this.usefulSwiper.swiper.realIndex==this.productList.length){
   // this.navig.back=false
  }
}
reloadLists(){
  this.messageService.sendMessage('reloadAgent')
 
}
  order(){
    return this.userlist.sort((a, b)=>{return a.otherJSON.order - b.otherJSON.order });
  }

}
