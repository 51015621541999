import { Component, OnInit, HostListener, Renderer2 } from '@angular/core';
import { GlobalService } from '../global.service';
import { MessageService } from '../message.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-headmenu',
  templateUrl: './headmenu.component.html',
  styleUrls: ['./headmenu.component.scss']
})
export class HeadmenuComponent implements OnInit {
  @HostListener('window:scroll', ['$event']) 
  scrollCheck(){
    ////console.log(window.scrollY)
    this.scroll=window.scrollY
  }
  @HostListener('window:keypress', ['$event']) 
  pressingEnter(event){
    if(event.code=="Enter" && this.searchInput.active && this.searchInput.keyword.length>2 && this.searchInput.keyword.length<100){
      this.keywordSearch()
    }
  }
  @HostListener('window:click', ['$event']) 
  pressing(event){
    if(this.searchInput.active==false){
      this.searchInput.show=false
    }
      
    
  }
public JSON=JSON
public scroll=0
public languages=this.global.languages
public language=this.global.language
public menu=[]

public menuCollapsed=true;

message: any
subscription: Subscription

public searchInput={
  active:false,
  show:false,
  keyword:''
}
  constructor(private route: ActivatedRoute,private global: GlobalService, private messageService: MessageService, private router: Router, private renderer: Renderer2) { 
    this.global.languageChecker().then(()=>{
      this.language = this.global.language
    })
    this.subscription = this.messageService.getMessage().subscribe(message => {
      this.message = message;
      if (this.message['text'] == 'UpdateLang') {
        this.language = this.message['element'];
       // this.navigateToFoo(this.language)
        //////console.log('maina', this.language)
      }
    });
  }

  async ngOnInit() {
    this.menu = await this.global.HTTPReq('GET', this.global.api, 'filesystem/getfile/alighment/upperMenu.json')
    this.route.queryParams.subscribe(params => {
      if(params['lang']){
       // ////console.log(params['lang'])
        this.doSomethig('changeLanguage','',params['lang'])
        }
  
        });
  }
  doSomethig(task, element?, value?) {
    if (task == "changeLanguage") {
      this.language = value
      this.global.language = value
      this.global.CookieService("SET",{name:'LANG',value:value})
      this.messageService.sendMessage('UpdateLang', value)
    }
    if(task=='mapClose'){
      this.messageService.sendMessage('mapClose')

    }
 
  }

  
  navigateToFoo(lang){
    // changes the route without moving from the current view or
    // triggering a navigation event,
    this.router.navigate([], {
     relativeTo: this.route,
     queryParams: {
       lang: lang
     },
     queryParamsHandling: 'merge',
     // preserve the existing query params in the route
     skipLocationChange: true
     // do not trigger navigation
   });
  }

  keywordSearch(){
    if(this.searchInput.keyword && this.searchInput.keyword.length){
      console.log('sūta keyword')

      this.router.navigateByUrl('/products?keyword='+this.searchInput.keyword)
      this.messageService.sendMessage('keywordSeaarch',this.searchInput.keyword)
      this.searchInput.active=false
    }
    
  }

}
