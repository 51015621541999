    <div class="notFound">
        404
        <div class="error">
            ERROR
        </div>
        <div class="errorText" [innerHTML]="'error.msg' | translate">
    
        </div>
        
    </div>
    <div class="agents">
        <app-agent [data]='{onlyTeam:true}' ></app-agent>
    </div>
