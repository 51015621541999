import { Component, OnInit, HostListener } from '@angular/core';
import { GlobalService } from 'src/app/global.service';
import { Subscription } from 'rxjs';
import { MessageService } from 'src/app/message.service';
import { filter, languages, alignmentTypes } from 'src/app/interfaces';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-middle',
  templateUrl: './middle.component.html',
  styleUrls: ['./middle.component.scss']
})
export class MiddleComponent implements OnInit {
  @HostListener('window:scroll',['$event'])
  checkActive(){
   if(this.sliderScelet && this.sliderScelet.length>0){
    for(let slide of this.sliderScelet){
     
      if(!this.scrolled[slide.title.LV]){
        
        let el=window.document.getElementById(slide.title.LV)
        if(this.isInView(el)){
          this.scrolled[slide.title.LV]=true
          //console.log('ielādē')
        }
      }
    }
   }
   this.redraw()
 
  }
  @HostListener('window:click',['$event'])
  navigated(){
    this.redraw()
  }
  
  public showAmount=80
  public sliderSceletTemp
  public sliderScelet
  
  public sliderSceletTeamOnTop=[]
  public scrolled={}
  public loading=true
  subscription: Subscription;
  constructor( private route: Router, private messageService: MessageService,private global:GlobalService) { 
    this.subscription = this.messageService.getMessage().subscribe(message => {
      
      if (message['text'] == 'loadingDone') {
        this.loading=false
      }
      if (message['text'] == 'sceletGot') {
        this.sliderSceletTemp=message['element']
        console.log('skelets atnaca',this.sliderSceletTemp)
        this.rearange().then(()=>{
          this.redraw()
          this.loading=false
        })
      }
      if(message["text"] == 'teamOnTop'){

        this.sliderScelet=this.sliderSceletTeamOnTop
        console.log('top:',this.sliderScelet)
      }
      if(message["text"] == 'teamOnBottom'){
        this.sliderScelet=this.sliderSceletTemp
        console.log('bottom:',this.sliderScelet)

      }

    });
    
    
  


  }

  async ngOnInit() {
    window.scrollTo(0,0)
    console.log(window.location.pathname)
   
  }

  doSomething(task){
    if(task=="showMore"){
      this.showAmount+=5
    }
  }

   isInView(el) {
     if(el){
      let box = el.getBoundingClientRect();
      if(box.top < window.innerHeight && (box.bottom-100) >= 0){
      return true
      }
     }
 
  }
  async rearange(){
    for(let part of this.sliderSceletTemp){
      if(part.type=='contacts'){
        this.sliderSceletTeamOnTop.unshift(part)
        continue
      }
      this.sliderSceletTeamOnTop.push(part)
    }
  }
  redraw(){
    if(window.location.pathname=='/'){
      this.sliderScelet=this.sliderSceletTemp
    }
    else{
      this.sliderScelet=this.sliderSceletTeamOnTop
    }
  }
}
