import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/global.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public clicked=false
  public thisYear =new Date().getFullYear()
  public window=window
  public links=[
    {
      name:"footer.navigation.privacy",
      url:'/page/38/privPolit"'
    }
 
  ]
  public settings
  constructor(private global:GlobalService) {

   }

  async ngOnInit() {
    await this.global.getSettings()
    this.settings=this.global.settings
  }
  toTop(){
    window.scrollTo(0,0)
  }

}
