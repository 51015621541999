import { Injectable, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '../environments/environment';
import { MessageService } from './message.service';
import { devices, filter, scelet } from './interfaces';



@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  public nowScrolling = false;
  public deviceType=devices.PC
  public imageSize={
    mobile:{
      loading:'20',
      questionImg:'small',
      testIMG:'medium',
      popup:'medium',
      itemCard:'medium',
      slider:'original'
    },
    tablet:{
      loading:'20',
      questionImg:'medium',
      testIMG:'medium',
      popup:'huge',
      itemCard:'medium',
      slider:'original'
    },
    pc:{
      loading:'5',
      questionImg:'medium',
      testIMG:'medium',
      popup:'large',
      itemCard:'medium',
      slider:'original'
    }
  }

  message: any;
  subscription: Subscription;

  thisUrl: Subscription;
 
  public filterOptions:filter = {
    objectType: { 10: true },
    excludeChildren:{
      4:{},
      1:{}
    },
    paymentType: 0,
    rooms: {
      from: null,
      to: null
    },
    price: {
      from: null,
      to: null
    },
    areaSize: {
      from: null,
      to: null
    },
    multiplier: 10000,
    location: {
      1: '',
      2: '',
      street:'Visas'
    },
    sort: 5,
    
  }
  public translations = {
    numberOfRooms2: {
      RU: 'Количество комнат',
      LV: 'ISTABAS',
      EN: 'Number of rooms'
    },
    numberOfRooms: {
      RU: 'Количество комнат',
      LV: 'ISTABA',
      EN: 'Number of rooms'
    },
    numberOfBathrooms: {
      RU: 'Количество комнат',
      LV: 'Vannasistabas',
      EN: 'Number of bathrooms'
    },
    numberOfBedrooms: {
      RU: 'Количество комнат',
      LV: 'Guļamistabas',
      EN: 'Number of bedrooms'
    }
    ,
    furniture: {
      RU: 'Количество комнат',
      LV: 'Mēbeles',
      EN: 'Furniture'
    }
    ,
    fireplace: {
      RU: 'Количество комнат',
      LV: 'Kamīns',
      EN: 'Fireplace'
    }
    ,
    buildingType: {
      RU: 'Количество комнат',
      LV: 'Istabu skaits',
      EN: 'Number of rooms'
    }
    ,
    buildingStructure: {
      RU: 'Количество комнат',
      LV: 'Istabu skaits',
      EN: 'Number of rooms'
    },
    floor: {
      RU: 'Количество комнат',
      LV: 'STĀVS',
      EN: 'Floor'
    },
    pricem2: {
      LV: 'Cena/M2',
      RU: 'Cena/M2',
      EN: 'Price/M2'
    }



  }
  public cookieNotification = false;
  public tests = []
  public api = environment.api;

  public languages = ['LV', 'RU', "EN"]
  public language = "LV"
  public langChanger = {
    'LV': 'lat',
    'RU': 'rus',
    'EN': 'eng'
  }
  public agents = []

  public objecttypes = {
    0: ['apartment'],
    1: ['businessLand', 'forestLand', 'residentialLand', 'socialLand', 'transportLand'],
    3: ['newBuilding'],
    2: ['house', 'housePart', 'rowHouse'],
    4: ['commercialDining', 'commercialManufacture', 'commercialBureau', 'commercialHostel', 'commercialTrade', 'commercialWarehouse'],
    5: ['house', 'housePart', 'rowHouse'],
    10: []
  }
  public sliderScelet:scelet[]

  public defoultMarker = {
    lat: '56.95960',
    lng: '24.10865',
    title: "SIA Immostate Alberta Iela 12-2, Riga, LV-1010, Latvija"
  }

  public settings={}
  constructor(private messageService: MessageService, private http1: HttpClient, private router: Router, private translate: TranslateService, ) {
    this.setDevice()
    this.languageChecker().then(() => {
      translate.setDefaultLang(this.language);
    })
    //saņemam globālo valodas mainu ziņu
    this.subscription = this.messageService.getMessage().subscribe(message => {
      this.message = message;
      if (this.message['text'] == 'UpdateLang') {
        this.language = this.message['element'];
        translate.use(this.language)
        //////console.log('Globāli maina' , this.language )
      }
    });
    this.getAgents()
   this.getSchelet()



    this.CookieService("GET", "token").then((cookie) => {
      if (cookie && cookie == 1) {
        return
      }
      this.cookieNotification = true
      //////console.log('cepums=',this.cookieNotification)
    })
   
  }

  getRouterURL() {
    return this.router.url
  }
  updateImage(imgSrc) {
    return `${imgSrc}?timeStamp=${Date.now()}`
  }
  async HTTPReq(type, API, location?, element?, AddHeader?): Promise<any> {
    if (!element) {
      element = {}
    }



    // //////console.log('notiek izsauciens uz ' + API)
    return new Promise((resolve, reject) => {
      let result;
      if (type == 'GET') {
        result = this.http1.get(API + location, element,
        ).subscribe(result => {
          //   //////console.log(result);
          let body = result;
          //let body = result;
          if (body) {
            return resolve(body);
          } else {
            return reject(body);
          }
        });
      }
      if (type == 'POST') {
        result = this.http1.post(API + location, element
        ).subscribe(result => {
          //   //////console.log(result);
          let body = result;
          //let body = result;
          if (body) {
            return resolve(body);
          } else {
            return reject(body);
          }
        });
      }
      if (type == 'DELETE') {
        result = this.http1.delete(API + location, element
        ).subscribe(result => {
          //   //////console.log(result);
          let body = result;
          //let body = result;
          if (body) {
            return resolve(body);
          } else {
            return reject(body);
          }
        });
      }

    });



  }
  async CookieService(task, element?) {
    //  //////console.log(task, element)
    if (task == 'SET') {
      if (!element.days) {
        element.days = ''
      }

      return await localStorage.setItem(element.name, JSON.stringify(element.value));

    }
    if (task == 'GET') {
      let value: string = await localStorage.getItem(element)
      if (value && value != '') {
        return value.replace(/['"]+/g, '')
      } else {
        return false
      }

    }
    if (task == 'GETJSON') {
      let value: string = await localStorage.getItem(element)
      if (value && value != '') {
        return JSON.parse(value)
      } else {
        return false
      }

    }

  }

  giveNumberSpaces(numberString) {
    if (typeof (numberString) == 'number') {
      numberString = (numberString).toFixed(0)

    }
    return numberString.replace(/(\d)(?=(\d{3})+$)/g, '$1 ');

  }

  transformProductList(productList) {
    for (let prod of productList) {
      prod.price = this.giveNumberSpaces(prod.price)
      if (prod.parrentData && prod.parrentData.lowestChildPrice) {
        prod.parrentData.lowestChildPrice = this.giveNumberSpaces(prod.parrentData.lowestChildPrice)
      }
      prod['smallParams'] = this.smallParams(prod)


    }
    return productList
  }

  async languageChecker() {
    await this.CookieService("GET", "LANG").then((cookie) => {
      ////console.log(cookie)
      if (!cookie) {
        return
      }
      this.language = cookie
    })
  }


  smallParams(objectEl) {

    let smallParams = {}
    let counte = 0
    for (let param of Object.keys(objectEl.params)) {
      if (counte > 2) {
        break
      }
      let pm = objectEl['params'][param]
      if (!this.isObject(pm) && param != 'visible') {

        if (pm) {
          smallParams[param] = pm
          counte++
        }
      }
    }
    ////console.log(smallParams)

    return smallParams

  }
  isObject(val) {
    return typeof val == "object"
  }
  async getAgents() {
    this.agents = await this.HTTPReq("POST", this.api, 'users/getUserList')
    let newList = []
    if (this.agents.length < 4) {
      for (let user of this.agents) {
        newList.push(user)
      }
      for (let user of this.agents) {
        newList.push(user)
      }
      this.agents = newList
    }
    return this.agents
  }
  async getSettings(){
    this.settings = await this.HTTPReq("POST", this.api, 'settings/getSettings')

  }
  async detach(json):Promise<any>{
    return await JSON.parse(JSON.stringify(json))
  }

  async scrollTo(x, y, elementToScroll?) {
    let windowToScroll: any = elementToScroll;
    if (!elementToScroll) {
      windowToScroll = window;
    }
    let x1 = 0;
    let y1 = windowToScroll.scrollTop;
    let yDiff = y - y1;
    console.log(y1, y);
    if (yDiff > 10 || yDiff < -10) {
      windowToScroll.scrollTo(0, y1 + (yDiff / 10));
      if (this.nowScrolling) {
        return;
      }
      setTimeout(() => {
        this.scrollTo(x, y, windowToScroll);
      }, 10);
    }
  }
  async getSchelet(){
    this.sliderScelet=await this.HTTPReq('GET',this.api,'filesystem/getfile/alighment/alighment.json')
    this.messageService.sendMessage('sceletGot',this.sliderScelet)
  }
  async setDevice(){
    if (window['innerWidth'] > 900) {
      this.deviceType = devices.PC
    }
    if (window['innerWidth'] < 900) {
      this.deviceType = devices.TABLET
    }
    if (window['innerWidth'] < 600) {
      this.deviceType = devices.MOBILE
    }
  }
}