import { Component, OnInit, Input } from '@angular/core';
import { GlobalService } from 'src/app/global.service';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'src/app/message.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-agent',
  templateUrl: './agent.component.html',
  styleUrls: ['./agent.component.scss']
})
export class AgentComponent implements OnInit {
  @Input('data') data:{
    onlyTeam:false,
    widget:false,
    agent:0
  }

  public agent
  message: any;
  subscription: Subscription;

public api =this.global.api

 public window=window
  public language="LV"
 public langChanger=this.global.langChanger
 public Object=Object;
public agentReady=false
  public userlist= this.global.agents
  public activeUser=<any>{}

  constructor( private route: ActivatedRoute, private global: GlobalService , private messageService:MessageService) {
    this.global.languageChecker().then(()=>{
      this.language = this.global.language
    })



    this.subscription = this.messageService.getMessage().subscribe(message => {
      this.message = message;
   
      if (this.message['text'] == 'UpdateLang') {
        this.language = this.message['element'];
        //////console.log('maina', this.language)
      }
      if (this.message['text'] == 'reloadAgent') {
        this.agentReady=false
        setTimeout(()=>{
          this.agentReady=true
        },5000)
      }
      
    });
   }

 async ngOnInit() {
   if(this.data && this.data.agent && this.data.widget){
   await  this.getAgent([this.data.agent])
   }
    if(this.global.agents.length<1){
      this.userlist=await this.global.getAgents() 
      
    }

    this.route.paramMap
    .subscribe(params => {
     for(let agent of this.userlist){
      if(agent.id==params['params'][params.keys[0]]){
        this.activeUser= agent
        setTimeout(()=>{
          this.agentReady=true
        },500)
      }
     }
     // ////console.log('products atverts='+params['params'][params.keys[0]])
      

    })
  }

async doSomething(task){
   if(task=='reload'){
    this.agentReady=false
     this.activeUser=null
    window.scrollTo(0,0)
   }
 }

 async getAgent(ids) {

   await this.global.HTTPReq("POST", this.global.api, 'users/getUserList', { ids }).then((res)=>{
    this.agent =res[0]
   })
  //console.log(this.agent)
}

}
