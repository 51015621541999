import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MessageService } from 'src/app/message.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.sass']
})
export class ProductsComponent implements OnInit {
  subscription: Subscription
  constructor( private messageService: MessageService) { 
    window.scrollTo(0,0)


  }

  ngOnInit() {
  }

}
