<div [class]=" scroll<55 ? 'menu':'menu fixes'">
        <div class="container">
            <div class="mobile">
                <a class=" link dib" href="tel:+371 23 999 333">
                    <div ><img _ngcontent-xys-c68="" src="assets/images/facebook-icon.png" class="smallimg"> +371 23 999 333</div>
    
                </a>
                <a class=" link dib" href="mailto:info@immostate.lv">
                    <div  ><img _ngcontent-xys-c68="" src="assets/images/email.png" class="smallimg"> info@immostate.lv</div>
                </a>
            </div>
            

            <nav class="navbar navbar-expand-lg navbar-light">
                <a (click)="menuCollapsed=true;doSomethig('mapClose')" class="navbar-brand" href="#"><img src="assets/images/logo.png" class="logo"></a>
                 <div [routerLink]="'/products'" class="search-icon ml-auto mobile-view ">
                    <a style="display: none;" class=" ddd link" (click)="searchInput.active=true;searchInput.show=true" (mouseover)="searchInput.active=true;searchInput.show=true" (mouseleave)="searchInput.active=false;searchInput.show=false">
                        <i class="material-icons fas fa-search mt-1" (click)="this.keywordSearch();this.searchInput.active=false"></i>
                        <div class="searchInput" *ngIf="searchInput.show">
                            <input (change)="this.keywordSearch()" maxlength=100 [(ngModel)]="searchInput.keyword"   type="text" id="searchI">
                        </div>
                    </a>                
                </div> 
                <button [class]="'navbar-toggler '" type="button" (click)="menuCollapsed?menuCollapsed=false:menuCollapsed=true">
                    <span class="navbar-toggler-icon"></span>
                </button>
  
                <div (click)="menuCollapsed=true" [class]="!menuCollapsed ?  'collapse navbar-collapse show':'collapse navbar-collapse'" id="navbarSupportedContent" >
                    <ul class="navbar-nav ml-auto text-center">

                        <li class="nav-item active" *ngFor="let men of menu;">
                        <a class=" ddd" [routerLink]="men.type=='object' ? ['/products'] : (men.type=='link' ? [men.link] : '')" [queryParams]='men.type=="object"? {params:JSON.stringify(men.filterOptions)} : ""  ' >{{men.title[language]}}</a>
                        </li>
                        <!-- <li class="nav-item active">
                            <a class=" ddd" [routerLink]="['/products']" [queryParams]='{params: JSON.stringify({"objectType":{"4":true},"paymentType":0,"rooms":{"from":1,"to":10},"price":{"from":0,"to":5000000},"areaSize":{"from":0,"to":100000},"multiplier":0.1,"location":{"1":"Visas","2":"Centrs"},"sort":0})}' >KOMERCĪPAŠUMI</a>
                        </li>
                        <li class="nav-item active">
                            <a class=" ddd"  routerLink="/page/39/parmums">PAKALPOJUMI</a>
                        </li>
                        <li class="nav-item active">
                            <a class=" ddd" routerLink="/page/37/parmums">PAR MUMS</a>
                        </li> -->
                         <li  class="nav-item active desktop-view">
                            <a class=" ddd link" [routerLink]="'/products'" (mouseover)="searchInput.active=true;searchInput.show=true" (mouseleave)="searchInput.active=false;searchInput.show=false">
                                <i class="material-icons fas fa-search mt-1" (click)="this.keywordSearch();this.searchInput.active=false"></i>
                                <div class="searchInput" *ngIf="searchInput.show">
                                    <input (change)="this.keywordSearch()" maxlength=100 [(ngModel)]="searchInput.keyword"   type="text" id="searchI">
                                </div>
                            </a>
                           
                        </li> 
                        <li class="nav-item dropdown">
                            <a class=" ddd dropdown-toggle active" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {{language}}
                            </a>
                            <div class="dropdown-menu text-center" aria-labelledby="navbarDropdown">
                                <ng-container *ngFor="let lang of languages; let i=index;">
                                <a class="dropdown-item" (click)="doSomethig('changeLanguage','',lang)" >{{lang}}</a>
                                    <div *ngIf="i+1 != languages.length;" class="dropdown-divider"></div>
                                </ng-container>
                                
                            </div>
                        </li>
                    </ul>
                </div>
            </nav> 
        </div>
    </div>