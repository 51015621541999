<app-headmenu></app-headmenu>
<div style="min-height:88px">
    <router-outlet></router-outlet>
</div>

<app-middle ></app-middle>

<div *ngIf="cookieNotification" class="cookieNotif">
    <div class="cookieText">
        {{('footer.acceptCookie'| translate)}}
        <a routerLink='/page/38/Tīmekļa-vietnes-privātuma-politika'>{{('footer.readMore'| translate)}}</a> 

    </div>
    
<div class="agree link" (click)="saveCookie()" >{{('footer.agree'| translate)}}</div>
</div>