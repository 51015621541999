<ng-container *ngIf="!data">
    <div *ngIf="activeUser && activeUser.name" class="agent">
        <div class="agentIMG "
            [style.background-image]="'url('+api+'filesystem/getfile/'+activeUser.otherJSON.img+')' | safe :'style'">
            <div class="agentData ">
                <div class="agentName">{{activeUser.name+' '+activeUser.surname}}</div>
                <div class="info fl">{{activeUser.otherJSON.desc[language]}}</div>
                <div class="phone fl">{{activeUser.otherJSON.soc[2].link}}</div>
                <div class="email fl">{{activeUser.otherJSON.soc[3].link}}</div>

                <div class="socIcons">
                    <a class="icon" *ngFor="let socNetw of activeUser.otherJSON.soc"
                        [href]="socNetw.title=='fb' ? 'https://m.me/'+socNetw.link : socNetw.title=='wpp' ? 'https://api.whatsapp.com/send?phone='+socNetw.link : socNetw.title=='tel' ? 'tel:'+socNetw.link : socNetw.title=='email' ? 'mailto:'+socNetw.link : ''">
                        <img [src]="'assets/img/contact/'+socNetw.title+'.svg'" class="icons">
                    </a>
                </div>
            </div>
        </div>

    </div>



    <ng-container>
        <app-slider *ngIf="agentReady&&activeUser && activeUser.name"
            [data]="{title:'agent.agentObjects' | translate , type:'object',agentID:activeUser.id, hideShowMore:true}"></app-slider>
        <!-- activeUser.otherJSON.brokersId -->
        <app-slider *ngIf="agentReady&&activeUser && activeUser.name"
            [data]="{title:'agent.agentBlogs' | translate , type:'blogPosts',agentID:activeUser.id, hideShowMore:true}"></app-slider>
    </ng-container>

    <section *ngIf="false" class="py-5 team">
        <div class="container-fluid">
            <div class="container pb-5">
                <h3 class="font-open-600 pb-5">MUSU KOMANDA</h3>
                <div class="row">



                    <ng-container *ngFor="let agent of userlist;let i = index;">
                        <div [routerLink]="'/agent/'+agent.id" (click)="doSomething('reload') " class="member dib link">
                            <div class="agentimg dib"
                                [style.background-image]="'url('+api+'filesystem/getfile/'+agent.otherJSON.imgClose+')' | safe:'style'">
                            </div>
                            <div class="info dib">
                                <div class="agentname">
                                    {{agent.name+' '+agent.surname}}</div>
                                <div class="jobTitle">{{agent.otherJSON.desc[language]}}
                                </div>
                                <div class="contacts">
                                    {{agent.otherJSON.soc[2].link}} <br>
                                    {{agent.otherJSON.soc[3].link}}
                                </div>
                            </div>
                        </div>
                    </ng-container>

                </div>
            </div>
        </div>
    </section>
</ng-container>

<ng-container *ngIf="data && data.onlyTeam">
    <ng-container *ngFor="let agent of userlist;let i = index;">
        <div [routerLink]="'/agent/'+agent.id" (click)="doSomething('reload') " class="member dib link">
            <div class="agentimg dib"
                [style.background-image]="'url('+api+'filesystem/getfile/'+agent.otherJSON.imgClose+')' | safe:'style'">
            </div>
            <div class="info dib">
                <div class="agentname">
                    {{agent.name+' '+agent.surname}}</div>
                <div class="jobTitle">{{agent.otherJSON.desc[language]}}
                </div>
                <div class="contacts">
                    {{agent.otherJSON.soc[2].link}} <br>
                    {{agent.otherJSON.soc[3].link}}
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>

<ng-container *ngIf="data && data.widget && data.agent">

        <div class="ag"  *ngIf="agent">
            <div class="img dib" [style.width]="'40%'">
                <div [style.background-image]="'url('+api+'filesystem/getfile/'+agent.otherJSON.img+')' | safe:'style'"
                    class="img-fluid agIMG"> </div>
            </div>
            <div class="info dib" [style.width]="'60%'">
                <div class="agName">{{agent.name.toUpperCase()}} {{agent.surname.toUpperCase()}}</div>
                <div class="agDesc">{{agent.otherJSON.desc[language]}}</div>
                <div class="agCont">
                    <a [href]="'tel:'+agent.otherJSON.soc[2].link.split(' ').join('')"></a>
                    {{agent.otherJSON.soc[2].link}}
                    <br>
                    {{agent.otherJSON.soc[3].link}}
                </div>
            
                <a *ngFor="let socNetw of agent.otherJSON.soc;"
                    [href]="socNetw.title=='fb' ? 'https://m.me/'+socNetw.link : socNetw.title=='wpp' ? 'https://api.whatsapp.com/send?phone=+371'+socNetw.link : socNetw.title=='tel' ? 'tel:'+socNetw.link : socNetw.title=='email' ? 'mailto:'+socNetw.link : ''">
                    <img [src]="'assets/img/contact/'+socNetw.title+'.svg'" class="iconsSmall"></a>
            </div>
        </div>
</ng-container>