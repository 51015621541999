import { Component, OnInit, Input, HostListener, ChangeDetectorRef } from '@angular/core';
import { GlobalService } from 'src/app/global.service';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { filter } from 'minimatch';
import { Subscription } from 'rxjs';
import { MessageService } from 'src/app/message.service';
import { TranslateService } from '@ngx-translate/core';
import { stringify } from 'querystring';

@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss']
})
export class SearchResultComponent implements OnInit {
  @HostListener("window:mousewheel", ["$event"])
  weel(event) {
    if (this.global.nowScrolling) {
      setTimeout(() => {
        this.global.nowScrolling = false;
        //console.log(this.global.nowScrolling)
      }, 100);
    }
    this.global.nowScrolling = true;
    //console.log(this.global.nowScrolling)
  }
  @HostListener('window:resize', ['$event'])
  resize() {
    this.calculateProdListWidth()
  }
  @HostListener('keyup', ['$event']) 
  fixing() {

    
  }
  @Input('data') data: {}

  public api = this.global.api

  public encodeURIComponent = encodeURIComponent
  public width = window.innerWidth
  public productWidth = 285
  public ListWidth = null
  public markers = []
  public JSON = JSON
  public cities = {}

  public mapPopup = false

  public sortFilters = {
    0: {
      LV: 'Cena',
      RU: 'Цена',
      EN: 'Price'
    },
    1: {
      LV: 'Platība',
      RU: 'Площадь',
      EN: 'Price'
    },
    2: {
      LV: 'Zemes platība',
      RU: 'Площадь земли',
      EN: 'Price'
    },
    3: {
      LV: 'Istabas',
      RU: 'Комнаты',
      EN: 'Rooms'
    },
    4: {
      LV: 'Stāvs',
      RU: 'Этаж',
      EN: 'Floor'
    },
    5: {
      LV: 'Jaunākais',
      RU: 'Новые',
      EN: 'Latest'
    }

  }

  public loading = false

  public keyword = ''
  public keywordGroup = ['Rīga']
  public objectTypes = [
    {
      title: { LV: 'Visi', RU: 'Все', EN: 'All' },
      value: 10,
      position: {
        x: 25,
        y: 53
      },
      children: []
    },
    {
      title: { LV: 'Dzīvokļi', RU: 'Квартиры', EN: 'Flats' },
      value: 0,
      position: {
        x: 94,
        y: 53
      },
      children: []
    },
    {
      title: { LV: 'Mājas', RU: 'Дома', EN: 'Houses' },
      value: 2,
      position: {

        x: 186,
        y: 53

      },
      children: []

    },
    {
      title: { LV: 'Jaunais Projekts', RU: 'Новый проект', EN: 'New Project' },
      value: 3,
      position: {
        x: 258,
        y: 53

      }
    },
    {
      title: { LV: 'Namīpašumi', RU: 'Домовладение', EN: 'Household' },
      value: 5,
      position: {
        x: 394,
        y: 53

      },
      children: []
    },
    {
      title: { LV: 'Komercīpašumi', RU: 'Коммерческая', EN: 'Commercial' },
      value: 4,
      position: {
        x: 504,
        y: 53
      },
      children: [
        //"catering", "industrial", "office", "residentailBuilding", "retail", "warehouse"
        'commercialDining', 'commercialManufacture', 'commercialBureau', 'commercialHostel', 'commercialTrade', 'commercialWarehouse'
      ]

    },
    {
      title: { LV: 'Zeme', RU: 'Земля', EN: 'Land' },
      value: 1,
      position: {
        x: 625,
        y: 53

      },

      children: [
        'businessLand', 'forestLand', 'residentialLand', 'socialLand', 'transportLand'
        // "buisnessLand", "forestLand", "socialLand", "residentialLand", "transportationLand"
      ]
    }
  ]

  public hoverPopup = {
    active: false,
    index: 0,
    x: 0,
    y: 0
  }
  public rooms = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  public filterOptions = this.global.filterOptions
  public fastSearchLocations = {}
  public fastDropdown = false
  public mapView = {
    lat: 56.946,
    lng: 24.10589
  }
  public Object = Object;
  public Number = Number;
  public language = "LV"
  public langChanger = this.global.langChanger
  public translations = this.global.translations
  public productList = []


  public ccounter = 0

  message: any
  subscription: Subscription
  constructor(private cd: ChangeDetectorRef,private global: GlobalService, private router: Router, private route: ActivatedRoute, private messageService: MessageService, private translate: TranslateService) {
    this.global.languageChecker().then(() => {
      this.language = this.global.language
    })
    this.subscription = this.messageService.getMessage().subscribe(async message => {
      this.message = message;
      if (this.message['text'] == 'UpdateLang') {
        this.language = this.message['element'];
        //////console.log('maina', this.language)
      }
      if (message['text'] == 'keywordSeaarch') {
        this.keyword =  message['element']
        await this.getData({ keyWord: this.keyword, active:true },false).then(()=>{
          setTimeout(()=>{
            if(!this.global.nowScrolling){
              this.scrollToID('keywordarea')

            }

          },500)

        })
       

      }
      if(message['text']=='mapClose'){
        this.mapPopup=false
      }
    });

  }

  async ngOnInit() {
    

    this.route.queryParamMap
      .subscribe(params => {

        //////console.log(params['params'][params.keys[0]])
        if(params.keys[0]=='keyword'){
          this.data['keyword']=params['params'][params.keys[0]]
        }else{
          if (params['params'][params.keys[0]]) {
            window.scrollTo(0, 0)
            this.filterOptions = JSON.parse(params['params'][params.keys[0]])
            if (!this.filterOptions.excludeChildren) {
              this.filterOptions.excludeChildren = {
                4: {},
                1: {}
              }
            }
            this.getData(this.filterOptions)
            ////console.log(JSON.stringify(this.filterOptions))
          }
        }
       
      })
    if (this.data['keyword']) {
      this.keyword = this.data['keyword']
      await this.getData({ keyWord: this.keyword, active:true },false)
    } else {
      await this.getData(this.filterOptions)
    }


    await this.getCities()
    ////console.log(this.markers)
    this.calculateProdListWidth()
  }
  async doSomething(task, element?, value?) {
    if (task == "fastsearch") {
      // ////console.log('keypress')
      this.fastDropdown = true
      this.fastSearchLocations = {}
      for (let city of this.Object.keys(this.cities).sort(this.sortComparer)) {
        //////console.log(city,this.keyword)
        if ((city.toLowerCase()).indexOf((element).toLowerCase()) != -1) {
          this.fastSearchLocations[city] = true
          ////console.log(city,element)
        }

      }

    }
    if (task == 'addkeyWord') {
      this.keywordGroup.push(JSON.parse(JSON.stringify(this.keyword)))
      this.keyword = ''
    }
    if (task == 'delKeyword') {
      this.keywordGroup.splice(element, 1)
    }
    if (task == 'objectType') {
      //  //console.log(this.filterOptions.objectType)
      if (element == 10) {
        //all
        let valueToGibe = true
        if (this.filterOptions.objectType[10]) {
          valueToGibe = false
        }
        for (let typee of this.objectTypes) {
          this.filterOptions.objectType[typee.value] = valueToGibe
        }

        return

      }
      this.filterOptions.objectType[10] = false

      let valueToGibe = true
      if (this.filterOptions.objectType[element]) {
        valueToGibe = false
      }
      this.filterOptions.objectType[element] = valueToGibe
    }
    if (task == "submitSearch") {
      this.router.navigateByUrl('/products?params=' + JSON.stringify(this.filterOptions))

      this.filterOptions.multiplier = 1000
      await this.getData(this.filterOptions)
      this.keyword=''
      if (element = 'map') {
        this.mapPopup = true
      }
    }
    if (task == 'hoverPopup') {
      // //console.log('hovering',element)
      if (this.objectTypes[element].value == 1 || this.objectTypes[element].value == 4) {

        this.hoverPopup = {
          active: true,
          index: element,
          x: value.clientX,
          y: value.clientY
        }

      }

    }
    if (task == 'addChildFilter') {
      let index = this.objectTypes[this.hoverPopup.index].value
      // if(element=='all' ){
      //   this.filterOptions.excludeChildren[index]={}
      //   return
      // }
      let par = this.filterOptions.excludeChildren[index]
      if (par[element]) {
        par[element] = false
        // //console.log(par)
        return
      }
      par[element] = true

      // //console.log(par)
      // //console.log('filterOptions.childrens[objectTypes[hoverPopup.index].value][element]')
    }
    if(task=='openMap'){
      await this.getData(this.filterOptions);
      this.mapPopup=true
    }
  }
  async getData(attr?,filtered:boolean=true) {
    this.global.filterOptions = this.filterOptions
    this.markers = []
    this.loading = true
    await this.global.HTTPReq("POST", this.global.api, 'products/getProducts'+(filtered?'Filtered':''), attr).then((res) => {
      this.productList = this.global.transformProductList(res)

      if (this.productList.length > 0) {
        let navCord = []
        for (let citiey of this.productList) {
          if (!citiey.location.geoCoordinates) {
            navCord.push(citiey.scoroID)
            continue
          }
          ////console.log(citiey.location.geoCoordinates)
          let location = citiey.location.geoCoordinates.split(',')

          this.markers.push({
            lat: location[0],
            lng: location[1],
            title:
              `
${citiey.location.street} ${(citiey.location.houseNumber ? citiey.location.houseNumber : '')} 
${citiey.price} €
`,
            id: citiey.id
          })

        }
        ////console.log('nav corsd',navCord)
      }


    })

    this.loading = false
  }
  async getCities() {

    await this.global.HTTPReq("POST", this.global.api, 'products/getProductsFast').then((res) => {
      this.cities = res;


    })
  }
  sortComparer(a, b) {
    let alfabet = 'AaĀāBbCcČčDdEeĒēFfGgĢģHhIiĪīJjKkĶķLlĻļMmNnŅņOoPpRrSsŠšTtUuŪūVvZzŽž'
    // //console.log(alfabet.indexOf(a.substr(0,1)) , alfabet.indexOf(b.substr(0,1)))
    if (alfabet.indexOf(a.substr(0, 1)) == alfabet.indexOf(b.substr(0, 1)))
      return 0;
    return alfabet.indexOf(a.substr(0, 1)) > alfabet.indexOf(b.substr(0, 1)) ? 1 : -1;
  }
  calculateProdListWidth() {
    let atstarpe = 20
    let windWith = window.innerWidth < 1500 ? window.innerWidth : 1500
    let amount = Math.floor(windWith / (this.productWidth + atstarpe))
    //console.log(amount, windWith)
    this.ListWidth = this.productWidth * this.Number(amount)

  }
  scrollToID(id) {
    setTimeout(() => {
      let child = document.getElementById(id);
      
      let parrent = document.getElementById("middleRef");
      console.log(child,parrent)
      let toScroll=child.offsetParent['offsetTop']-child.offsetTop
      console.log(toScroll,child.offsetTop,parrent.clientHeight);
      //this.global.scrollTo(0, -1*toScroll, parrent);
      window.scrollTo(0, (-1*toScroll)-100);
    }, 100);
  }
  checkInput(event:Event){
     this.makeFromValueSmallerThanTo()
  }
  makeFromValueSmallerThanTo(){
   let paramsToCheck={'areaSize':7,'price':7,'rooms':3}
      for(let param of Object.keys(paramsToCheck)){
        let from=this.filterOptions[param].from
        let to =this.filterOptions[param].to
        let length=paramsToCheck[param]
        if( to<from){
          to=from
        }

        if(from){
          console.log(from,((from).toString()).length, length)

        }
        if(from && ((from).toString()).length > length){
          from = ((from).toString()).slice(0, length)
        }
        if(to && ((to).toString()).length > length){
          to = ((to).toString()).slice(0, length)
        }

       
       
        this.filterOptions[param].from=from
        this.filterOptions[param].to=to
      }
    
   
  }
}
