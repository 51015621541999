import { Component, OnInit, Input } from '@angular/core';


import { ActivatedRoute } from '@angular/router';
import { GlobalService } from 'src/app/global.service';
import { MessageService } from 'src/app/message.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PageComponent implements OnInit {

  @Input('page') pageIdPassed:{
    id:number,
    type:number
  }
  public linked
  
  message: any;
  subscription: Subscription;

  public language = 'LV';

  public opened=false;
  public count=0;

  public page = <any>{};
  public pages=[]
  public allPages=false

  public api =this.global.api
  public pageId;
  public slider=this.global.sliderScelet
  constructor(private route: ActivatedRoute, private global: GlobalService, private messageService: MessageService) {

    this.route.queryParamMap.subscribe(params => {
 
      if(params['params']['linked']){
        this.linked=params['params']['linked']
       
      }

    })
    this.route.paramMap
      .subscribe(params => {
   
        let pageId = params['params'][params.keys[0]];
        this.pageId=pageId
        if(pageId){     
          this.page={}
          let par=pageId.split('b')
          let type=1
          if(par.length>1){
            type=par[1]
            pageId=par[0]
          }
           this.getPage(pageId,type)
        
          
        }
       
         if(!pageId){
           this.allPages=true
          this.getPages(10000)
         }else{
          this.allPages=false
          this.getPages(3)
         }
      })

     
     


    this.subscription = this.messageService.getMessage().subscribe(message => {
      this.message = message;
      if (this.message['text'] == 'UpdateLang') {
        this.language = this.message['element'];
       // ////console.log('maina', this.language)
      }
      if (message['text'] == 'sceletGot') {
        this.slider=message['element']
      }
    });

    

    this.language = this.global.language

  }

  async ngOnInit() {
    window.scrollTo(0, 0)
    if(this.pageIdPassed){
      let pageId =  this.pageIdPassed.id;
      this.getPage(pageId,this.pageIdPassed.type)
    }
  }


  async getPage(pageId?,type?){
    if(!type){
      type=1
    }
      let para = { id: pageId,type:type,public:true }
    this.global.HTTPReq('POST', this.global.api, 'pages/getPages', para).then((result) => {
      this.page=result[0]
      if(this.page.desc[this.language]){
        this.page.desc[this.language]=this.modifyImageMaxWidth( this.page.desc[this.language])
      }
      
     //console.log(this.page)

    })
  }

  async getPages(multiplier){
    let para = { type:2, multiplier,public:true}
    this.pages= await this.global.HTTPReq('POST', this.global.api, 'pages/getPages', para)
}

changePage(){
  this.messageService.sendMessage('changePage')
}

 modifyImageMaxWidth(htmlString: string): string {
  // Step 1: Parse the HTML string into a DOM Document
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  // Step 2: Find all <img> elements and modify their max-width
  doc.querySelectorAll('img').forEach(img => {
      img.style.maxWidth = '100%';
      img.style.height='auto'
  });

  // Step 3: Serialize the document back into a string
  // Option A: Using XMLSerializer if you need to ensure XHTML compliance
  // const serializer = new XMLSerializer();
  // const modifiedString = serializer.serializeToString(doc);

  // Option B: Using innerHTML for HTML content
  const modifiedString = doc.body.innerHTML; // This gets the modified inner HTML of the body element

  return modifiedString;
}
}




