import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LandpageComponent } from './home/middle/landpage/landpage.component';
import { ProductComponent } from './home/middle/product/product.component';
import { PageComponent } from './pages/pages.component';
import { BlogComponent } from './blog/blog.component';
import { ProductsComponent } from './home/middle/products/products.component';
import { ServicesComponent } from './home/middle/services/services.component';
import { VacancesComponent } from './home/middle/vacances/vacances.component';
import { AgentComponent } from './home/middle/agent/agent.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { RedirectorComponent } from './redirector/redirector.component';

const routes: Routes = [
   { path:'',component: LandpageComponent},
   { path:'object/:id',component: ProductComponent},
   { path:'product',component: ProductComponent},
   { path:'products',component: ProductsComponent},
   { path:'blog',component: BlogComponent},
   { path:'blog/:keyword',component: BlogComponent},
   { path:'blog/:id/:seo',component: BlogComponent},
   { path:'page/:id/:seo',component: PageComponent},
   { path:'pages',component: PageComponent},
   { path:'services',component: ServicesComponent},
   { path:'services/:id',component: ServicesComponent},
   { path:'vacances',component: VacancesComponent},
   { path:'vacances/:id',component: VacancesComponent},
   { path:'agent/:id',component: AgentComponent},
   { path:'residence-object',component: ProductComponent},
   {path:'commercial-object',component: ProductComponent},
   {path:'investment-object',component: ProductComponent},

   { path: '**', component:RedirectorComponent }
  ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
