
    <div class="toTop link" *ngIf="window.scrollY>400" (click)="window.scrollTo({top: 0,left: 0,behavior: 'smooth'})" ><div class="scroll"></div></div>
        <!---Contact-Us-->
        <section style="position: relative;
        top: 7px;">
                <div class="contact-us py-5">
                    <div class="container">
                        <div class="dib contactInfo ">
                            <h2 class="font-open-600">{{('questions.title'| translate)}}</h2><p></p>
                            <p class="font-light" [innerHTML]="('questions.text'| translate)" ></p>
                            
                            <div class="linkCont">
                                <div class="img dib"><img src="assets/images/facebook-icon.png" class="img-fluid mr-2"></div>
                                <div class="text dib">{{ settings&& settings['otherJSON'] ? settings['otherJSON'].conntactInfo.phone : '' }}</div>
                            </div>
                            <div  class="linkCont">
                                <div class="img dib"><img src="assets/images/email.png" class="img-fluid mr-2"></div>
                                <div class="text dib">{{ settings && settings['otherJSON'] ? settings['otherJSON'].conntactInfo.email : '' }}</div>
                            </div>
                            <div  class="linkCont">
                                <div class="img dib"><img src="assets/images/location.png" class="img-fluid mr-2"></div>
                                <div class="text dib">{{ settings && settings['otherJSON'] ? settings['otherJSON'].conntactInfo.adress : '' }}</div>
                            </div>



                        </div>
                        <div class="dib mapArea" [class.inable]="!clicked" (click)="clicked=true">
                            <div class="map" [class.inable]="!clicked">
                                <app-map [data]="{width:'100%',height:'450px'}"></app-map>
                                <!-- <iframe style="position: relative; top: 7px;" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2175.404075521962!2d24.104369615948926!3d56.95901058089312!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46eecfc5b1db3e4b%3A0x867130a682d124f0!2sIMMOSTATE!5e0!3m2!1sen!2s!4v1572008296038!5m2!1sen!2s" width="100%" height="450" frameborder="0" style="border:0;" allowfullscreen=""></iframe> -->
                            </div>
                        </div>
                    </div>
                </div>
            </section><!---->
        
            <!----Map---->
            <section>
              
            </section><!----->
        
    
    <div class="footer" >

        <div class="centred">
                <div style="    text-align: center;
                padding-top: 28px;">
                    <div class="link dib footerlinks" (click)="toTop()" *ngFor="let link of links " [routerLink]="[link.url]" [queryParams]='{params:link.params}'  >{{link.name | translate}} </div>
           
                </div>
                <hr class="divider mt-4">
                <div class="row">
                    <div class="col-lg-4 mt-4">
                        <img src="assets/images/footer-logo.png" class="img-fluid">
                    </div>
                    <div class="col-lg-4 mt-4 text-center">
                        <a href="https://www.facebook.com/immostate.lv"><img src="assets/social/021-facebook-white.svg" class="footer-logo"></a>
                        <a href="https://www.linkedin.com/company/immostate"><img src="assets/social/045-linkedin-white.svg" class="footer-logo mx-3"></a>
                        <a href="https://www.instagram.com/immostate_realty/"><img src="assets/social/025-instagram-white.svg" class="footer-logo"></a> 
                    </div>
                    <div class="col-lg-4 mt-4 text-right text-white">
                        
                        <p class="para-robo rights" >&#169; 2004-{{thisYear}} IMMOSTATE <br> {{'footer.rights' | translate}} </p>
                    </div>
                </div>
        </div>

       
    </div>

<title>Komercīpašumi un investīcijas, nekustamais īpašums, vērtēšana</title>
<meta name="description" content="Immostate piedāvā nekustamā īpašuma un biznesa aktīvu pakalpojumus, ar mērķi nodrošināt klientam maksimālos ieņēmumus un ilgtermiņa vērtības pieaugumu" />

