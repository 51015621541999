<div class="head">
    <!-- <app-headmenu></app-headmenu> -->

</div>
<ng-container *ngIf="productList && productList[counter]">



    <div class="pakalparea">
        <div class="img"
            [style.background-image]="'url('+api+'filesystem/getfile/'+productList[counter].img+'/original)' | safe:'style'">

        </div>
        <div class="texts">
            {{productList[counter].title[language]}}
            <br>
            <div [class]="'rings'">
                <div (click)="counter=i" *ngFor="let ring of productList;let i = index"
                    [class]="counter==(i) ?'ring dib active link':'ring dib link'"></div>
            </div>
        </div>

    </div>
    <div class="area" [innerHTML]="productList[counter].desc[language] | safe :'html'">

    </div>
    <app-slider  [data]="{title:service.title, type:service.type}" ></app-slider>

</ng-container>

