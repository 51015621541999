import { Component, OnInit, Input, ViewChild, ElementRef, ViewChildren, Renderer2 } from '@angular/core';
import * as L from 'leaflet';
//import 'leaflet-draw';
import { Router } from '@angular/router';
import { GlobalService } from '../global.service';
declare var ol: any;





@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {
@Input('data') data:{
  centre:{
    lat:number
    lng:number
  },
  markers:[{
    id:string
    title:string
    lat:number
    lng:number
  }],
  zoom:number
  height:number
  width:number
}
public drawn=false
public mymap
public mapID=(new Date().getTime()).toString()
public ImmoIcon:any = L.icon({
    iconUrl: "assets/images/location1.png",
    iconAnchor: [15, 46],
    iconSize:[30, 46]
});
public objectIcon:any = L.icon({
  iconUrl: "assets/images/marker-icon.png",
  iconAnchor: [12.5, 41],
  iconSize:[25, 41]
});
public mapView={
  //,/@56.959163,24.106596
  lat:56.959480,
  lng:24.108770,
  zoom:16
}
  constructor( private renderer: Renderer2, private router:Router,private global:GlobalService) { }

  async ngOnInit() {
    setTimeout( async ()=>{
      //console.log(this.data)
      await this.firstMapInit()
     await this.drawingMarkers()
    },100)
  }
  logElement({target}) {
    if(target && target.nodeName == "LI") {
    //////console.log('Target id: ', target.id);
    // Add Business Logic here
  }
}
 async drawingMarkers(){
   if(!this.drawn){
    //console.log(this.data.markers)
    // mymap.clearLayers();
     setTimeout(()=>{
       if(this.data.markers && this.data.markers.length>0){
         let i=0
        for(let marker of this.data.markers){
          // L.Marker([46.947, 7.4448]).on('click', markerOnClick).addTo(map)
          marker.title=marker.title.replace(/<[^>]*>/g, '');
          setTimeout(()=>{
            L.marker([marker.lat, marker.lng],{customID:marker.id,title:marker.title,icon:this.objectIcon}).on('click', this.markerClick).addTo(this.mymap);
          },i*10)
          i++
          }
          this.drawn=true
       }
     // this.setCenter()
     this.drawingMarkers()
     },1000)
   }
  }

  setCenter() {
    this.mymap= L.map(this.mapID).setView([this.data.centre.lat, this.data.centre.lng], this.data.zoom)
    this.mymap.setZoom(8);
  }

  async markerClick(e){
    let id =e.sourceTarget.options.customID
    if(id){
      //////console.log(e.sourceTarget.options.customID)
      window.open('https://'+window.location.host+'/object/'+id)
    }
  }
  async oppenRealMap(e){
    let id =e.sourceTarget.options.customID
    ////console.log(id)
  }

  async firstMapInit(){
    if(!this.data.centre || !this.data.centre.lat || !this.data.centre.lng || this.data.markers.length<1){
      //console.log('1')
      this.mymap=await L.map(this.mapID).setView([Number(this.global.defoultMarker.lat), Number(this.global.defoultMarker.lng)], this.mapView.zoom)

    }else{
      //console.log('2',this.mymap)
      this.mymap=await L.map(this.mapID).setView([this.data.centre.lat, this.data.centre.lng], this.data.zoom)
    }
    await L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: 'Open Street Map',tms: false }).addTo(this.mymap);
    ////console.log(this.data)
      L.marker([Number(this.global.defoultMarker.lat),Number(this.global.defoultMarker.lng)],{customID:'0', title:this.global.defoultMarker.title,icon:this.ImmoIcon}).on('click', this.oppenRealMap).addTo(this.mymap);

  }
}
