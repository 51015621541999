import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from 'src/app/global.service';
import { SwiperOptions } from 'swiper';
import { SwiperComponent } from 'angular2-useful-swiper';
import { Subscription } from 'rxjs';
import { MessageService } from 'src/app/message.service';
import htmlToFormattedText from "html-to-formatted-text";
export type product = {
  id: number;
  name: any;
  desc: any;
  price: number;
  img: string;
  galery: any[];
  active: boolean;
  brokersIds: any[];
  location: any;
  booked: boolean;
  size: any;
  rooms: number;
  floor: any;
  categories: any[];
  scoroID: number;
  type: any;
  otherJSON: any;
  params: any;
  hierarchy: any;
  signedUser: number;
  timestamp: number;
  parrentData:any
}
@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {
  @ViewChild('usefulSwiper') usefulSwiper: SwiperComponent;
  @ViewChild('smallSlide') smallSlide: ElementRef;

  public htmlToFormattedText=htmlToFormattedText
  public preKey={
              LV:'Nekustamais īpašums',
              EN:'Real Estate',
              RU:'Недвижимость'
            }
  message: any;
  subscription: Subscription;

  public api = this.global.api

  public fullscreenImg = ''
  public encodeURIComponent = encodeURIComponent
  public deviceType = "pc"
  public imageSize = {
    mobile: {
      slider: 'small',
      objectIMG: 'medium',
      popup: 'medium'
    },
    pc: {
      slider: 'small2',
      objectIMG: 'huge',
      popup: 'large'
    }
  }
  public markers = []

  public Number = Number

  public filterOptions = {
    active: false,
    objectType: {},
    excludeIDS: [],
    excludeChildren: {
      4: {},
      1: {}
    },
    paymentType: 0,
    rooms: {
      from: null,
      to: null
    },
    price: {
      from: null,
      to: null
    },
    areaSize: {
      from: null,
      to: null
    },
    multiplier: 10000,
    location: {
      1: 'Rīga',
      2: 'Centrs'
    },
    sort: 5,
    recursive: true

  }
  public readmore = false
  public product:product
  public language = "LV"
  public langChanger = this.global.langChanger
  public Object = Object;
  public translations = this.global.translations
  public mapSettings = {
    width: '100%',
    height: '300px'
  }
  public agents = []
  public popup = false;
  public selectedImg = 0
  config: SwiperOptions = {
    loop: true,
    slidesPerView: 1,

  };
  public mapView = {
    lat: '0',
    lng: '0',
    title: ''

  }
  constructor(private route: ActivatedRoute, private global: GlobalService, private messageService: MessageService, private router:Router) {
    if (window.innerWidth < 900) {
      this.deviceType = 'mobile'
    } else {
      this.deviceType = 'pc'
    }
    this.global.languageChecker().then(() => {
      this.language = this.global.language
    })



    this.subscription = this.messageService.getMessage().subscribe(message => {
      this.message = message;

      if (this.message['text'] == 'UpdateLang') {
        this.language = this.message['element'];
      }
    });

  }

  async ngOnInit() {
    window.scrollTo(0, 0)

    await this.route.paramMap
      .subscribe(async params => {
        // //////console.log('products atverts='+params['params'][params.keys[0]])
        let id = params['params'][params.keys[0]]
        if(id){
          await this.getData({ id: id })
          console.log('ssssssssssssssssss',id)
        }
       
      })

    this.route.queryParams.subscribe(async params => {
      if (params['id']) {
        await this.getData({ id: params['id'] })
        console.log('ssssssssssssssssss2',params['id'])
        this.router.navigateByUrl('/object/'+params['id'])

      }
    });




  }
  async getData(keyWord?) {
    this.markers = []
    this.product = null
    this.filterOptions.active = false
    await this.global.HTTPReq("POST", this.global.api, 'products/getProducts', keyWord).then((res) => {
      res = this.global.transformProductList(res)

      this.product = res[0];
      if(!this.product || !this.product.active){
        this.router.navigateByUrl('404')
      }
      this.filterOptions.excludeIDS[this.product.id] = true
      if (this.product.location.geoCoordinates) {
        let location = this.product.location.geoCoordinates.split(',')
        this.mapView.lat = location[0]
        this.mapView.lng = location[1]
        this.markers.push({ lat: location[0], lng: location[1], title: this.product.location.street + ' ' + (this.product.location.houseNumber ? this.product.location.houseNumber : '') })
        ////console.log('map Pushed',this.markers)
      }
      else {
        ////console.log('no map details')
      }
      if ((this.product.signedUser || this.product.brokersIds.length > 0) && this.product.hierarchy.type == 'child') {
        //  //////console.log('getting Agent ',this.product.signedUser)
        let ids = []
        if (this.product.brokersIds.length > 0) {
          this.product.brokersIds
          ids = [...ids, ...this.product.brokersIds]
        }
        if (this.product.signedUser) {
          ids = [...ids, this.product.signedUser]
        }
        this.getAgent(ids)

        //this.options.marker.setLatLng( this.product.location.geoCoordinates)
        //this.marker.setLatLng( this.product.location.geoCoordinates).addTo(this.map)

      }
      if (this.product.hierarchy.type == 'parent') {
        let ids = [...this.product.brokersIds, ...this.product.parrentData.asignedAgents]
        console.log(ids)
        this.getAgent(ids)

      }
      if(this.product && this.product.img){
        this.product.galery.push(this.product.img)
      }

    })
    window.scrollTo(0, 0)
    this.getSimilarObject()
    // ////console.log(this.filterOptions)
  }

  doSomething(task, element?) {
    ////////console.log(task,element)
    if (task == 'next') {
      this.usefulSwiper.swiper.slideNext();
      this.selectedImg = this.usefulSwiper.swiper.realIndex
      this.smallSlide.nativeElement.scrollTo(this.selectedImg * 135, 0)
    }
    if (task == 'back') {
      this.usefulSwiper.swiper.slidePrev();
      this.selectedImg = this.usefulSwiper.swiper.realIndex
      this.smallSlide.nativeElement.scrollTo(this.selectedImg * 135, 0)

    }
    if (task == 'jumpToImg') {
      this.popup = true
      this.usefulSwiper.swiper.slideTo(element + 1, 1500);
      this.selectedImg = element
    }
  }
  async getAgent(ids) {

    this.agents = await this.global.HTTPReq("POST", this.global.api, 'users/getUserList', { ids })
    ////////console.log(this.agents)
  }

  isObject(val) {
    return typeof val == "object"
  }
  isNumber(val) {
    return typeof val == "number"
  }

  getSimilarObject() {

    for (let objTye of Object.keys(this.global.objecttypes)) {
      if (this.global.objecttypes[objTye].indexOf(this.product.type.object[0]) != -1) {
        this.filterOptions.objectType = { [objTye]: true }

      }

    }
    this.filterOptions.location[1] = this.product.location.levels[1]
    this.filterOptions.location[2] = this.product.location.levels[2]
    this.filterOptions.active = true
  }
}
