<div id="middleRef">
        <ng-container *ngIf="data['filter'];">
                <div *ngIf='width>768' class="filtreArea2 dectop">
                        <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="100%" version="1.1"
                                shape-rendering="geometricPrecision" text-rendering="geometricPrecision"
                                image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 756 212"
                                xmlns:xlink="http://www.w3.org/1999/xlink">
                                <g id="Layer_x0020_1">
                                        <metadata id="CorelCorpID_0Corel-Layer" />
                                        <rect fill="none" stroke="#CCCCCC" stroke-width="0.755906" width="756" height="212" />
                                        <line fill="none" stroke="#F5F7F8" stroke-width="1.00006" x1="25" y1="134" x2="733"
                                                y2="134" />
                                        <line fill="none" stroke="#F5F7F8" stroke-width="1.00006" x1="25" y1="76" x2="733"
                                                y2="76" />
                                        <foreignObject x="25" y="29" class="textInSVG">
                                                <xhtml:div xmlns="http://www.w3.org/1999/xhtml">
                                                        <div>{{('header.filter.type.title'| translate).toUpperCase()}}:</div>
                                                </xhtml:div>
                                        </foreignObject>
        
        
        
        
        
        
        
                                        <g id="_2172564576832">
                                                <rect class="link" (click)="filterOptions.paymentType=0" fill="white"
                                                        stroke="#E6E6E6" stroke-width="1.00006" x="25" y="111" width="10.0003"
                                                        height="9.50023" rx="2" ry="2" />
                                                <rect class="link" (click)="filterOptions.paymentType=1" fill="white"
                                                        stroke="#E6E6E6" stroke-width="1.00006" x="95" y="111" width="10.0003"
                                                        height="9.50023" rx="2" ry="2" />
        
                                                <foreignObject x="25" y="84" class="textInSVG">
                                                        <xhtml:div xmlns="http://www.w3.org/1999/xhtml">
                                                                <div>{{('header.paymentType'| translate).toUpperCase()}}:</div>
                                                        </xhtml:div>
                                                </foreignObject>
        
        
                                                <foreignObject (click)="filterOptions.paymentType=0" x="40" y="109"
                                                        class="textInSVGsmall " style="width:50px">
                                                        <xhtml:div xmlns="http://www.w3.org/1999/xhtml">
                                                                <div>{{('header.sell'| translate).toUpperCase()}}</div>
                                                        </xhtml:div>
                                                </foreignObject>
                                                <foreignObject (click)="filterOptions.paymentType=1" x="108" y="109"
                                                        class="textInSVGsmall ">
                                                        <xhtml:div xmlns="http://www.w3.org/1999/xhtml">
                                                                <div>{{('header.rentAnd'| translate).toUpperCase()}}</div>
                                                        </xhtml:div>
                                                </foreignObject>
                                                <polyline *ngIf="filterOptions.paymentType==0" fill="none" stroke="#4B667E"
                                                        stroke-width="1.00006" points="27,115 29,120 33,112 " />
                                                <polyline *ngIf="filterOptions.paymentType==1" fill="none" stroke="#4B667E"
                                                        stroke-width="1.00006" points="97,115 99,120 103,112 " />
                                        </g>
        
                                        <g id="_2172564597824">
                                                <foreignObject fill="none" stroke="#E6E6E6" stroke-width="1.00006" x="242"
                                                        y="107" width="44.0005" height="15.7501" rx="9" ry="3">
                                                        <xhtml:div xmlns="http://www.w3.org/1999/xhtml">
                                                                <input (change)="checkInput($event)" class="svgInput" type="number"
                                                                        [(ngModel)]="filterOptions.price.from"></input>
                                                        </xhtml:div>
                                                </foreignObject>
                                                <foreignObject fill="none" stroke="#E6E6E6" stroke-width="1.00006" x="314"
                                                        y="107" width="70" height="15.7501" rx="9" ry="3">
                                                        <xhtml:div xmlns="http://www.w3.org/1999/xhtml">
                                                                <input (change)="checkInput($event)" class="svgInput" type="number"
                                                                        [(ngModel)]="filterOptions.price.to"></input>
                                                        </xhtml:div>
                                                </foreignObject>
                                                <text x="224" y="120" fill="#1C4161" font-weight="normal"
                                                        font-size="8.51377px">{{('header.filter.from'| translate).toUpperCase()}}:</text>
                                                <text x="290" y="120" fill="#1C4161" font-weight="normal"
                                                        font-size="8.51377px">{{('header.filter.to'| translate).toUpperCase()}}:</text>
                                                <foreignObject x="224" y="84" class="textInSVG">
                                                        <xhtml:div xmlns="http://www.w3.org/1999/xhtml">
                                                                <div>{{('header.filter.price'| translate).toUpperCase()}}</div>
                                                        </xhtml:div>
                                                </foreignObject>
        
        
                                        </g>
                                        <g id="_2172564596448"
                                                [attr.class]="filterOptions.objectType[10] || filterOptions.objectType[0] || filterOptions.objectType[2] ? ' ' :' disabled'">
        
        
                                                <foreignObject fill="none" stroke="#E6E6E6" stroke-width="1.00006" x="593"
                                                        y="107" width="41.0004" height="15.7501" rx="8" ry="3">
                                                        <xhtml:div xmlns="http://www.w3.org/1999/xhtml">
                                                                <input  (change)="checkInput($event)" class="svgInput" type="number"
                                                                        [(ngModel)]="filterOptions.rooms.from"></input>
                                                        </xhtml:div>
                                                </foreignObject>
                                                <foreignObject fill="none" stroke="#E6E6E6" stroke-width="1.00006" x="664"
                                                        y="107" width="50" height="15.7501" rx="9" ry="3">
                                                        <xhtml:div xmlns="http://www.w3.org/1999/xhtml">
                                                                <input (change)="checkInput($event)" class="svgInput" type="number"
                                                                        [(ngModel)]="filterOptions.rooms.to"></input>
                                                        </xhtml:div>
                                                </foreignObject>
        
                                                <text x="573" y="120" fill="#1C4161" font-weight="normal"
                                                        font-size="8.51377px">{{('header.filter.from'| translate).toUpperCase()}}:</text>
                                                <text x="639" y="120" fill="#1C4161" font-weight="normal"
                                                        font-size="8.51377px">{{('header.filter.to'| translate).toUpperCase()}}:</text>
                                                <foreignObject x="574" y="84" class="textInSVG">
                                                        <xhtml:div xmlns="http://www.w3.org/1999/xhtml">
                                                                <div>
                                                                        {{('header.rooms'| translate).toUpperCase()}}
        
                                                                </div>
                                                        </xhtml:div>
                                                </foreignObject>
        
                                        </g>
                                        <g id="_2172564595040">
        
                                                <foreignObject fill="none" stroke="#E6E6E6" stroke-width="1.00006" x="412"
                                                        y="107" width="44.0005" height="15.7501" rx="9" ry="3">
                                                        <xhtml:div xmlns="http://www.w3.org/1999/xhtml">
                                                                <input (change)="checkInput($event)" class="svgInput" type="number"
                                                                        [(ngModel)]="filterOptions.areaSize.from"></input>
                                                        </xhtml:div>
                                                </foreignObject>
                                                <foreignObject fill="none" stroke="#E6E6E6" stroke-width="1.00006" x="485"
                                                        y="107" width="70" height="15.7501" rx="8" ry="3">
                                                        <xhtml:div xmlns="http://www.w3.org/1999/xhtml">
                                                                <input (change)="checkInput($event)" class="svgInput" type="number"
                                                                        [(ngModel)]="filterOptions.areaSize.to"></input>
                                                        </xhtml:div>
                                                </foreignObject>
        
        
        
        
                                                <text x="394" y="120" fill="#1C4161" font-weight="normal"
                                                        font-size="8.51377px">{{('header.filter.from'| translate).toUpperCase()}}:</text>
                                                <text x="460" y="120" fill="#1C4161" font-weight="normal"
                                                        font-size="8.51377px">{{('header.filter.to'| translate).toUpperCase()}}:</text>
                                                <g>
                                                        <foreignObject x="393" y="84" class="textInSVG">
                                                                <xhtml:div xmlns="http://www.w3.org/1999/xhtml">
                                                                        <div>
                                                                                {{('header.areaSize'| translate).toUpperCase()}}
                                                                                M<sup>2</sup>
        
                                                                        </div>
                                                                </xhtml:div>
                                                        </foreignObject>
        
                                                </g>
                                        </g>
        
                                        <g id="_2172564589472">
                                                <foreignObject x="25" y="152" class="textInSVG">
                                                        <xhtml:div xmlns="http://www.w3.org/1999/xhtml">
                                                                <div>{{('header.location'| translate).toUpperCase()}}:</div>
                                                        </xhtml:div>
                                                </foreignObject>
        
        
                                                <foreignObject x="460" y="152" class="textInSVG">
                                                        <xhtml:div xmlns="http://www.w3.org/1999/xhtml">
                                                                <div>{{('header.filterBy'| translate).toUpperCase()}}:</div>
                                                        </xhtml:div>
                                                </foreignObject>
                                                <foreignObject fill="none" stroke="none" x="25" y="170" width="70%" height="23">
                                                        <xhtml:div xmlns="http://www.w3.org/1999/xhtml">
                                                                <select (change)="filterOptions.location[2]=''"
                                                                        *ngIf="cities && Object.keys(cities).length>0"
                                                                        style="font-size: 12px;" class=" dib citySVG" name=""
                                                                        id="" [(ngModel)]='filterOptions.location[1]'
                                                                        (change)="filterOptions.location[2]=''">
        
                                                                        <option value="" selected disabled>
                                                                                {{'header.city'|translate}}</option>
                                                                        <option value="Visas" selected>
                                                                                {{'header.all'|translate}}</option>
                                                                        <option *ngFor="let itm of Object.keys(cities).sort(sortComparer);"
                                                                                [value]="itm">{{itm}}</option>
                                                                </select>
                                                                <select *ngIf="cities && Object.keys(cities).length>0 && filterOptions.location[1] && filterOptions.location[1]!='Visas'"
                                                                        style="font-size: 12px;" class=" dib citySVG" name=""
                                                                        id="" [(ngModel)]='filterOptions.location[2]'
                                                                        (change)="filterOptions.location.street=''">
                                                                        <option value="" selected disabled>
                                                                                {{'header.region'|translate}}</option>
                                                                        <option value="Visas" selected>
                                                                                {{'header.all'|translate}}</option>
        
                                                                        <option *ngFor="let itm of Object.keys(cities[filterOptions.location[1]]).sort(sortComparer);"
                                                                                [value]="itm">{{itm}}</option>
                                                                </select>
        
                                                                <select *ngIf="cities && Object.keys(cities).length>0 && filterOptions.location[2] && filterOptions.location[2]!='Visas'"
                                                                        style="font-size: 12px;" class=" dib citySVG" name=""
                                                                        id="" [(ngModel)]='filterOptions.location.street'>
        
                                                                        <option value="" selected disabled>
                                                                                {{'header.street'|translate}}</option>
                                                                        <option value="Visas" selected>
                                                                                {{'header.all'|translate}}</option>
        
                                                                        <ng-container
                                                                                *ngFor="let itm of cities[filterOptions.location[1]][filterOptions.location[2]] | keyvalue : sortComparer;">
                                                                                <option [value]="itm.key"
                                                                                        *ngIf="itm.value==1 || itm.value[filterOptions.paymentType==0?'sale':'rent']">
                                                                                        <ng-container *ngIf="itm.key!='Visas' ">
                                                                                                {{itm.key}} 
                                                                                                <!-- -({{itm.value[filterOptions.paymentType==0?'sale':'rent']}})   //te bija skaitlis kurš parādija cik objekti rezultātos --> 
                                                                                        </ng-container>
                                                                                        <ng-container *ngIf="itm.key=='Visas'">
                                                                                                {{itm.key}}
                                                                                        </ng-container>
                                                                                </option>
                                                                        </ng-container>
        
                                                                </select>
        
                                                        </xhtml:div>
                                                </foreignObject>
                                                <foreignObject fill="none" stroke="none" x="460" y="170" width="200"
                                                        height="23">
                                                        <xhtml:div xmlns="http://www.w3.org/1999/xhtml">
        
                                                                <select style="font-size: 12px; " class=" dib citySVG" name=""
                                                                        id="" [(ngModel)]='filterOptions.sort'>
                                                                        <option value="" selected disabled>
                                                                                {{'header.doFilter'|translate}}</option>
                                                                        <option *ngFor="let itm of Object.keys(sortFilters);"
                                                                                [value]="itm">{{sortFilters[itm][language]}}
                                                                        </option>
                                                                </select>
                                                        </xhtml:div>
                                                </foreignObject>
                                        </g>
        
        
        
        
                                        <g id="_2172564592320">
                                                <rect class="link searchBut" (click)='doSomething("submitSearch")'
                                                        fill="#D8852B" x="580" y="164" width="152" height="33.9999" rx="2"
                                                        ry="2" />
                                                <text class="link" (click)='doSomething("submitSearch")' x="634" y="186"
                                                        fill="white" font-weight="normal"
                                                        font-size="10.9119px">{{'header.search'|translate}}</text>
                                        </g>
        
        
                                        <ng-container *ngFor="let checkbox of objectTypes;let i =index;">
                                                <g id="_2172564588448"
                                                        [attr.transform]="'translate('+checkbox.position.x+' 53)'"
                                                        (mouseleave)="hoverPopup.active=false">
                                                        <polyline [attr.transform]="'translate(-25 -53)'"
                                                                *ngIf="filterOptions.objectType[checkbox.value]" fill="none"
                                                                stroke="#4B667E" stroke-width="1.00006"
                                                                points="27,56 29,61 33,53 " />
                                                        <rect (click)="doSomething('objectType',checkbox.value)"
                                                                (mouseenter)="   doSomething('hoverPopup',i,$event)"
                                                                class="link" fill-opacity='0' fill="white" stroke="#E6E6E6"
                                                                stroke-width="1.00006" x="0" y="0" width="10.0003"
                                                                height="9.50023" rx="2" ry="2" />
                                                        <foreignObject
                                                                [attr.style]="(i==hoverPopup.index && hoverPopup.active ? ' width: 120;height: 180;':'border:none')| safe:'style' "
                                                                x="12" y="-2" class=" link textInSVGsmall">
                                                                <xhtml:div xmlns="http://www.w3.org/1999/xhtml">
                                                                        <div (click)="doSomething('objectType',checkbox.value)"
                                                                                (mouseenter)="  doSomething('hoverPopup',i,$event)">
                                                                                {{(checkbox.title[language]).toUpperCase()}}:
                                                                        </div>
        
        
                                                                        <div
                                                                                [class]="i==hoverPopup.index && filterOptions.objectType[objectTypes[i].value]? 'hoverpopup':'disabledFull hoverpopup '">
                                                                                <!-- <input type="checkbox" name="" id="" (click)="doSomething('addChildFilter','all')" > {{'header.all' | translate}} -->
        
                                                                                <div class="checkbox"
                                                                                        *ngFor="let checkbox of objectTypes[i].children">
                                                                                        <input *ngIf='filterOptions.excludeChildren && objectTypes[i].value && filterOptions.excludeChildren[objectTypes[i].value]' type="checkbox"
                                                                                                [attr.checked]="!filterOptions.excludeChildren[objectTypes[i].value][checkbox]"
                                                                                                name="" id=""
                                                                                                (click)="doSomething('addChildFilter',checkbox)" />
                                                                                        {{'header.filter.type.'+checkbox | translate}}
        
                                                                                </div>
                                                                        </div>
        
        
                                                                </xhtml:div>
                                                        </foreignObject>
        
        
                                                </g>
                                        </ng-container>
        
                                </g>
        
                        </svg>
        
        
                </div>
                <div *ngIf='width<768' class="filtreArea2 mobile">
                        <div class="filter">
                                {{'header.sort'|translate}} <select style="font-size: 12px;margin-left: 8%; "
                                        class=" dib citySVG" name="" id="" [(ngModel)]='filterOptions.sort'>
                                        <option value="" selected disabled>{{'header.doFilter'|translate}}</option>
                                        <option *ngFor="let itm of Object.keys(sortFilters);" [value]="itm">
                                                {{sortFilters[itm][language]}}</option>
                                </select>
                                <div class="block">
                                        <div class="title">{{('header.filter.type.title'| translate).toUpperCase()}}:</div>
                                        <div class="checkboxes">
        
                                                <div class="checkbox dib" (click)="doSomething('objectType',checkbox.value)"
                                                        *ngFor="let checkbox of objectTypes;let i =index;"> <input
                                                                [(ngModel)]="filterOptions.objectType[checkbox.value]"
                                                                type="checkbox">{{checkbox.title[language]}}</div>
        
        
        
        
        
                                        </div>
                                        <hr>
                                        <div class="title">{{'header.paymentType'|translate}}:</div>
                                        <div class="checkboxes">
        
                                                <div class="checkbox dib" (click)="filterOptions.paymentType=0">
                                                        <input [checked]="filterOptions.paymentType==0"
                                                                type="checkbox">{{'header.sell'| translate}}</div>
                                                                
                                                <div class="checkbox dib" (click)="filterOptions.paymentType=1">
                                                        <input [checked]="filterOptions.paymentType==1"
                                                                type="checkbox">{{'header.rentAnd'| translate}}</div>
                                        </div>
                                        <hr>
                                        <div class="title">{{'header.filter.price'| translate}}:</div>
                                        <div class="checkboxes">
                                                <div class="checkbox dib">
                                                        {{('header.filter.from'| translate).toUpperCase()}}
                                                        
                                                        <input (change)="checkInput($event)" class="svgInput" type="number" maxlength="7" max="7"
                                                                [(ngModel)]="filterOptions.price.from">
        
                                                </div>
                                                <div class="checkbox dib">
                                                        {{('header.filter.to'| translate).toUpperCase()}}
                                                        <input (change)="checkInput($event)" class="svgInput" type="number" maxlength="7"
                                                                [(ngModel)]="filterOptions.price.to">
                                                </div>
                                        </div>
                                        <hr>
                                        <div class="title">{{'header.areaSize'| translate}} (m<sup>2</sup>) :</div>
                                        <div class="checkboxes">
        
                                                <div class="checkbox dib">
                                                        {{('header.filter.from'| translate).toUpperCase()}}
                                                        <input (change)="checkInput($event)" class="svgInput" type="number"
                                                                [(ngModel)]="filterOptions.areaSize.from">
        
                                                </div>
                                                <div class="checkbox dib">
                                                        {{('header.filter.to'| translate).toUpperCase()}}
                                                        <input (change)="checkInput($event)" class="svgInput" type="number"
                                                                [(ngModel)]="filterOptions.areaSize.to">
                                                </div>
                                        </div>
                                        <hr>
                                        <div class="title">{{'header.rooms'| translate}}:</div>
                                        <div class="checkboxes">
        
                                                <div class="checkbox dib">
                                                        {{('header.filter.from'| translate).toUpperCase()}}
                                                        <input (change)="checkInput($event)" class="svgInput" type="number"
                                                                [(ngModel)]="filterOptions.rooms.from">
        
                                                </div>
                                                <div class="checkbox dib">
                                                        {{('header.filter.to'| translate).toUpperCase()}}
                                                        <input (change)="checkInput($event)" class="svgInput" type="number"
                                                                [(ngModel)]="filterOptions.rooms.to">
                                                </div>
                                        </div>
                                        <hr>
                                        <div class="title">{{'header.location'| translate}}:</div>
                                        <div class="checkboxes">
                                                <select *ngIf="cities && Object.keys(cities).length>0" style="font-size: 12px;"
                                                        class=" dib citySVG" name="" id=""
                                                        [(ngModel)]='filterOptions.location[1]'>
                                                        <option value="" selected disabled>{{'header.city'|translate}}</option>
                                                        <option *ngFor="let itm of Object.keys(cities);" [value]="itm">{{itm}}
                                                        </option>
                                                </select>
                                                <select *ngIf="cities && Object.keys(cities).length>0 && filterOptions.location[1]"
                                                        style="font-size: 12px;" class=" dib citySVG" name="" id=""
                                                        [(ngModel)]='filterOptions.location[2]'>
                                                        <option value="" selected disabled>{{'header.region'|translate}}
                                                        </option>
                                                        <option *ngFor="let itm of Object.keys(cities[filterOptions.location[1]]);"
                                                                [value]="itm">{{itm}}</option>
                                                </select>
        
        
                                        </div>
                                        <div class="searchButt link" (click)='doSomething("submitSearch")' x="634" y="186"
                                                fill="white" font-weight="normal" font-size="10.9119px">
                                                {{'header.search'|translate}}</div>
                                </div>
        
        
                        </div>
        
        
                </div>
        
        
        
        </ng-container>
        
        <ng-container *ngIf="data['startfilter'];">
                <div class="filterPlace">
                        <div class="top">
                                <!-- <div [class]="filterOptions.paymentType==0?'itSel dib selected': 'itSel dib'"
                                        (click)="filterOptions.paymentType=0">{{('header.sell'| translate).toUpperCase()}}</div>
                                <div [class]="filterOptions.paymentType==1?'itSel dib selected': 'itSel dib'"
                                        (click)="filterOptions.paymentType=1">{{('header.rent'| translate).toUpperCase()}}</div> -->
        
                        </div>
                        <div class="allFilter" *ngIf='width>768'>
                                <div class="par dib" style="width: 16%;">
                                        <select class="uniq" name="" id=""
                                                (change)="filterOptions.objectType={};doSomething('objectType',$event.target.value)">
                                                <option value="0" selected disabled>
                                                        {{('header.filter.type.title'| translate).toUpperCase()}}</option>
                                                <option *ngFor="let itm of objectTypes;" [value]="itm.value">
                                                        {{itm.title[language]}}</option>
                                        </select>
                                </div>
                                <div class="par dib" style="width: 15%;">
                                        <select [class]="'uniq '" name="" id="" [(ngModel)]="filterOptions.paymentType">
                                                <option value="0" selected disabled>
                                                        {{('header.paymentType'| translate).toUpperCase()}}</option>
                                                <option  [value]="0">{{('header.sell'| translate).toUpperCase()}}</option>
                                                <option  [value]="1">{{('header.rent'| translate).toUpperCase()}}</option>
                                        </select>
                                        <select *ngIf="false" [disabled]=" !filterOptions.objectType[0] && !filterOptions.objectType[2]"
                                                [class]=" filterOptions.objectType[0] || filterOptions.objectType[2] ? 'uniq ' :'uniq disabled'"
                                                name="" id=""
                                                (change)="filterOptions.rooms.from=$event.target.value;filterOptions.rooms.to=$event.target.value">
                                                <option value="#" selected disabled>
                                                        {{('header.rooms'| translate).toUpperCase()}}</option>
                                                <option *ngFor="let itm of rooms;" [value]="itm">{{itm}}</option>
                                        </select>
                                </div>
                                <div class="par dib" style="width: 20%; padding-right: 0;padding-left: 0;">
                                        <div style="width: 100%;" class="all">
                                                <div class="title2 dib top">{{('header.filter.price'| translate).toUpperCase()}}
                                                        (€)</div>
                                                <input (change)="checkInput($event)"  [(ngModel)]="filterOptions.price.from" class="inp2 dib top"
                                                        [placeholder]="('header.filter.from'| translate).toUpperCase()"
                                                        type="number" maxlength="7">
                                                <input (change)="checkInput($event)" [(ngModel)]="filterOptions.price.to" class="inp2 dib top"
                                                        placeholder="{{('header.filter.to'| translate).toUpperCase()}}"
                                                        type="number" maxlength="7">
                                        </div>
        
                                </div>
                                <div class="par dib" style="width: 20%;  padding-right: 0;padding-left: 0;">
                                        <div style="width: 100%;" class="all">
                                                <div class="title2 dib top">{{('header.areaSize'| translate).toUpperCase()}}
                                                        (m<sup>2</sup>)</div>
                                                <input (change)="checkInput($event)" [(ngModel)]="filterOptions.areaSize.from" class="inp2 dib top"
                                                        placeholder="{{('header.filter.from'| translate).toUpperCase()}}"
                                                        type="number" maxlength="7">
                                                <input (change)="checkInput($event)" [(ngModel)]="filterOptions.areaSize.to" class="inp2 dib top"
                                                        placeholder="{{('header.filter.to'| translate).toUpperCase()}}"
                                                        type="number" maxlength="7">
                                        </div>
                                </div>
                                <div class="par dib" style="width: 29%;" *ngIf="cities && Object.keys(cities).length>0">
        
                                        <div class="list dib city">
                                                <input type="text" class="inputWhite"
                                                        (focus)="doSomething('fastsearch',filterOptions.location[1])"
                                                        (input)="doSomething('fastsearch',filterOptions.location[1])"
                                                        [placeholder]="('header.city'|translate).toUpperCase()"
                                                        [(ngModel)]="filterOptions.location[1]"
                                                        *ngIf="filterOptions.location[1]!='Visas'">
                                                <div *ngIf="filterOptions.location[1]=='Visas'"
                                                        (click)="filterOptions.location[1]=''">{{ ('header.all'| translate)}}
                                                </div>
                                                <div class="dropDownList"
                                                        *ngIf="Object.keys(fastSearchLocations).length>0 && fastDropdown">
                                                        <div class="itm link"
                                                                (click)="filterOptions.location[1]='Visas'; fastDropdown=false">
                                                                {{('header.all'| translate)}}</div>
                                                        <ng-container
                                                                *ngFor="let itm of Object.keys(fastSearchLocations); let i = index;">
        
                                                                <div *ngIf="itm!='Visas' && itm!='undefined'" class="itm link"
                                                                        (click)="filterOptions.location[1]=itm">
                                                                        {{itm}}</div>
                                                        </ng-container>
        
        
                                                </div>
                                        </div>
        
        
        
                                        <!-- <select class=" dib city" name="" id="" [(ngModel)]='filterOptions.location[1]'>
                                                <option value="" selected disabled>Pilsēta</option>
                                                <option *ngFor="let itm of Object.keys(cities)" [value]="itm">{{itm}}</option>
                                        </select> -->
        
        
        
                                        <div class="list dib city">
        
                                                <div *ngIf="filterOptions.location[1]=='Visas'">{{('header.all'| translate)}}
                                                </div>
                                                <input *ngIf="filterOptions.location[1] && cities[filterOptions.location[1]] && filterOptions.location[1]!='Visas'"
                                                        type="text" class="inputWhite" (focus)="fastDropdown=true"
                                                        [placeholder]="('header.region'|translate).toUpperCase()"
                                                        [(ngModel)]='filterOptions.location[2]'>
        
                                                <div class="dropDownList"
                                                        *ngIf="filterOptions.location[1] && cities[filterOptions.location[1]]  && fastDropdown">
                                                        <div class="itm link"
                                                                (click)="filterOptions.location[2]='Visas'; fastDropdown=false">
                                                                {{('header.all'| translate)}}</div>
                                                        <ng-container
                                                                *ngFor="let itm of Object.keys(cities[filterOptions.location[1]]).sort(sortComparer); let i = index;">
                                                                <div *ngIf="itm!='Visas'&& itm!='undefined'" class="itm link"
                                                                        (click)="filterOptions.location[2]=itm; fastDropdown=false">
                                                                        {{itm}}</div>
                                                        </ng-container>
        
                                                </div>
                                        </div>
        
        
                                        <!-- <select *ngIf="filterOptions.location[1] && cities[filterOptions.location[1]]" class=" dib city" name="" id=""
                                                [(ngModel)]='filterOptions.location[2]'>
                                                <option value="" selected disabled>Reģions</option>
                                                <option *ngFor="let itm of Object.keys(cities[filterOptions.location[1]])"
                                                        [value]="itm">{{itm}}</option>
                                        </select> -->
        
        
        
        
                                </div>
        
                        </div>
                        <div class="allFilter mobile" *ngIf='width<769'>
                                <div class="par dib" style="width: 46%;" *ngIf="cities && Object.keys(cities).length>0">
        
                                        <select class=" city" name="" id="" [(ngModel)]='filterOptions.location[1]'>
                                                <option value="" selected disabled>{{('header.city'|translate).toUpperCase()}}
                                                </option>
                                                <option *ngFor="let itm of Object.keys(cities)" [value]="itm">{{itm}}</option>
                                        </select>
                                        <!-- <select *ngIf="filterOptions.location[1]" class=" city" name="" id=""
                                                [(ngModel)]='filterOptions.location[2]'>
                                                <option value="" selected disabled>{{'header.region'|translate}}</option>
                                                <option *ngFor="let itm of Object.keys(cities[filterOptions.location[1]]);"
                                                        [value]="itm">{{itm}}</option>
                                        </select> -->
        
        
        
        
                                </div>
                                <div class="par dib" style="width: 46%;">
                                        <select class="uniq" name="" id=""
                                                (change)="filterOptions.objectType={};doSomething('objectType',$event.target.value)">
                                                <option value="#" selected disabled>
                                                        {{('header.filter.type.title'| translate).toUpperCase()}}</option>
                                                <option *ngFor="let itm of objectTypes;" [value]="itm.value">
                                                        {{itm.title[language]}}</option>
                                        </select>
                                </div>
        
                                <div class="par dib" style="width: 46%;">
                                        <input (change)="checkInput($event)" [(ngModel)]="filterOptions.price.from" class="inp2 dib top"
                                                [placeholder]="('header.filter.price' | translate ).toUpperCase() +' '+ ('header.filter.from'| translate).toUpperCase()"
                                                type="number" maxlength="7">
                                </div>
                                <div class="par dib" style="width: 46%;">
                                        <input (change)="checkInput($event)" [(ngModel)]="filterOptions.price.to" class="inp2 dib top"
                                                [placeholder]="('header.filter.price' | translate ).toUpperCase() +' '+('header.filter.to'| translate).toUpperCase()"
                                                type="number" maxlength="7">
                                </div>
        
        
                        </div>
        
                        <div class="bottom">
        
                                <button [routerLink]="['/products']" class="btn-white font-open mt-3 text-sm advencedSearch">
                                        {{('header.advencedSearch'| translate)}}</button>
        
        
                                <button 
                                        (click)='doSomething("submitSearch")'
                                        class="btn-yellow mt-3">{{('header.search'| translate)}}</button><br>
                                <button class="btn-white font-open mt-3 text-sm"
                                        (click)="doSomething('openMap')"><span class="pr-3"><i
                                                        class="fas fa-map-marker-alt"></i></span>
                                        {{('header.showInMap'| translate)}}</button>
                        </div>
                        <div class="mapbg"></div>
                        <div *ngIf="mapPopup" class="map">
                                <div class="mapClose link" (click)="mapPopup=false">x</div>
                                <app-map [data]="{markers:markers,zoom:10,centre:mapView,width:'100%',height:'100%'}"></app-map>
                        </div>
                </div>
        </ng-container>
        
        <div *ngIf="keyword" id="keywordarea" class="keywordarea" >Meklēts pēc atslēgas vārda: <span class="keyword"> {{this.keyword}} </span> </div>
        <ng-container *ngIf="data['allList'];">
                <div class="itmList">
                        <div class="itemAlign" [style.width]="ListWidth?ListWidth+'px':'initial'">
                                <ng-container *ngFor="let slide of productList;">
        
        
                                        <div *ngIf="!loading" class="card dib">
        
                                                <div class="img-card link" [routerLink]="'/object/'+slide.id">
                                                        <hr class="blue-line-1">
                                                        <div [routerLink]="'/object/'+slide.id" class="img"
                                                                [style.background-image]="'url('+api+'filesystem/getfileFromURL/'+encodeURIComponent(slide['img'])+'/medium/cover)' | safe:'style'">
                                                        </div>
                                                        <div class="prize top"
                                                                *ngIf="false && (slide.type.transaction=='rent' || slide.type.transaction=='lease')">
                                                                
                                                                <h6 style="font-size: 0.7rem;">
                                                                        {{'header.'+(slide.type.transaction) | translate}}
                                                                </h6>
                                                                <hr class="yellow-lines">
                                                        </div>
                                                        <div class="prize">
                                                                <h6> {{slide.hierarchy.type=='parent' && slide.parrentData.lowestChildPrice ? 'Sākot no:' : ''}}
                                                                        {{slide.hierarchy.type=='parent' && slide.parrentData && slide.parrentData.lowestChildPrice ? slide.parrentData.lowestChildPrice +' EUR': '€ '+slide['price']}}
                                                                </h6>
                                                                <hr class="yellow-lines">
                                                        </div>
        
                                                </div>
        
                                                <div class="c">
                                                        <div style="padding-top: 15px;">
                                                                <h5 class="kk">
                                                                        <div class="dib ma" *ngIf="slide.location.levels[2]">
                                                                                {{slide.location.levels[2].toUpperCase() +', '}}
                                                                        </div>
                                                                        <div class="dib ma" *ngIf="slide.location.street">
                                                                                {{' '+(slide.location.street.length<20? slide.location.street.toUpperCase():(slide.location.street.toUpperCase()).substr(0,20)+'...')}}
                                                                        </div>
                                                                </h5>
        
                                                                <div class="slideObjCardText">
                                                                        <!-- only landingSize and areaSize M2 -->
                                                                        <ng-container
                                                                                *ngFor="let param of Object.keys(slide['size']) ; let i = index;">
                                                                                <div class="dib params" *ngIf=" slide['size'][param]>0 && ('params.'+param | translate) !=''">
                                                                                        <span class="paramName one">
                                                                                                {{Number(slide['size'][param]).toFixed(0)}}
        
                                                                                        </span>
        
                                                                                        <span class="paramName two">
                                                                                                <ng-container
                                                                                                        *ngIf="param=='areasize'">
                                                                                                        M<sup>2</sup>
                                                                                                </ng-container>
                                                                                                <ng-container
                                                                                                        *ngIf="param=='landsize'">
                                                                                                        <span 
                                                                                                                style="font-size: inherit;"
                                                                                                                [innerHTML]="'params.landSize' | translate">
                                                                                                        </span>
                                                                                                </ng-container>
                                                                                        </span>
                                                                                        <span *ngIf="(Object.keys(slide['size']).length>1 && i==0) || Object.keys(slide.smallParams).length>0"
                                                                                                        class="vertLine">|</span>
                                                                                </div>
        
                                                                        </ng-container>
                                                                        <!-- <span *ngIf="slide.size.areasize>0 || slide.size.landsize>0 ">
                                                                        {{slide.size.areasize>0 ? slide.size.areasize :slide.size.landsize}}
                                                                        ((m<sup>2</sup>))
                                                                    </span>
                                                                    <span *ngIf="slide.otherJSON.numberOfRooms">
                                                                        <span class="vertLine">|</span>
                                                                        {{ slide.otherJSON.numberOfRooms}}
                                                                        {{slide.otherJSON.numberOfRooms<1? translations.numberOfRooms[language] : translations.numberOfRooms2[language] }}
        
                                                                    </span>
                                                                    <span *ngIf="slide.floor.amount>0 && slide.floor.current ">
                                                                        <span class="vertLine">|</span>
                                                                        {{slide.floor.current+'.'+translations.floor[language]}}
                                                                        ({{ slide.floor.current +'/'+slide.floor.amount}})
                                                                    </span>
                                                                    <span *ngIf="slide.type.pricem2 && false">
                                                                        <span class="vertLine">|</span>
        
                                                                        {{ slide.type.pricem2.toFixed(0)}}<sup>€/((m<sup>2</sup>))</sup>
                                                                    </span> -->
        
                                                                        <ng-container *ngIf="slide.params;">
                                                                                <ng-container
                                                                                        *ngFor="let param of Object.keys(slide.smallParams) ; let i = index;"
                                                                                        class="allParams">
        
        
                                                                                        <ng-container>
                                                                                                <span class="paramName"
                                                                                                        *ngIf="slide['smallParams'][param] && slide['smallParams'][param]!==true" >
                                                                                                        {{  param=='areaSize' || param=='landSize' ? Number(slide['smallParams'][param]).toFixed(0) : slide['smallParams'][param] }}{{param=='floorNumber'?'.':''}}
                                                                                                </span>
        
                                                                                                <span class="paramName"
                                                                                                        [innerHTML]="'params.'+param | translate">
        
                                                                                                </span>
        
                                                                                                <span class="paramName"
                                                                                                        *ngIf="param=='floorNumber'">
                                                                                                        ({{  slide['params'][param]+(slide['params'].numberOfFloors ? '/'+slide['params'].numberOfFloors :'') }})
                                                                                                </span>
                                                                                                <span *ngIf="i!=2 && i!=Object.keys(slide.smallParams).length-1"
                                                                                                        class="vertLine">|</span>
                                                                                        </ng-container>
        
        
                                                                                </ng-container>
                                                                        </ng-container>
        
                                                                        <!-- params -->
                                                                        <!-- <ng-container
                                                                        *ngFor="let param of Object.keys(slide['params']) ; let i = index">
                                                                        <ng-container class="allParams">
                                                                            <ng-container>
        
                                                                                {{translations[param] && translations[param][language] ? translations[param][language]+':' : ''}}
        
                                                                            </ng-container>
                                                                            <ng-container>
                                                                                {{slide['params'][param] && slide['params'][param][language] ? slide['params'][param][language] : slide['otherJSON'][param]}}
                                                                            </ng-container>
        
        
                                                                            {{  Object.keys(slide['params']).length > (i+1) ? '|':''}}
                                                                        </ng-container>
        
                                                                    </ng-container> -->
                                                                        <div class="lowerText"
                                                                                [innerHTML]="(slide.name[langChanger[language]].substr(0,60)).toUpperCase() | safe:'html'"
                                                                                *ngIf="slide.name[langChanger[language]]"></div>
                                                                </div>
        
        
                                                                <div class="btn-1">
                                                                        <button class="blue-btn text-white dis-block"
                                                                                [routerLink]="'/object/'+slide.id"
                                                                                type="button">{{'slider.open' | translate}}</button>
                                                                </div>
                                                        </div>
                                                </div>
        
                                        </div>
        
        
                                </ng-container>
        
                                <ng-container *ngIf="productList.length<1 && !loading">
                                        <div class="noItemWarning">
                                                {{('products.noItemsFound'| translate)}}
                                        </div>
                                </ng-container>
                                <ng-container *ngIf="loading">
                                        <div class="loading">
                                                <img class="loadingImg" src="assets/images/spinner.svg" alt="">
                                        </div>
                                </ng-container>
                        </div>
        
                </div>
        
        </ng-container>
</div>
