import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';

import { HeaderComponent } from './home/header/header.component';
import { MiddleComponent } from './home/middle/middle.component';
import { FooterComponent } from './home/footer/footer.component';
import { LandpageComponent } from './home/middle/landpage/landpage.component';
import { SearchResultComponent } from './home/middle/search-result/search-result.component';
import { ProductComponent } from './home/middle/product/product.component';
import { SliderComponent } from './home/middle/slider/slider.component';
import 'hammerjs';
import { Angular2UsefulSwiperModule } from 'angular2-useful-swiper';
import { SafePipe } from './safe.pipe';
import { FormsModule }   from '@angular/forms';
import { HttpClientModule,HttpClient } from '@angular/common/http';
import { HeadmenuComponent } from './headmenu/headmenu.component'; 

import { LeafletModule } from '@asymmetrik/angular2-leaflet';
import { LeafletDrawModule } from '@asymmetrik/angular2-leaflet-draw';
import { MapComponent } from './map/map.component';
import { PageComponent } from './pages/pages.component';
import { BlogComponent } from './blog/blog.component';
import { ProductsComponent } from './home/middle/products/products.component';
import { ServicesComponent } from './home/middle/services/services.component';
import { ContactFormComponent } from './home/middle/contact-form/contact-form.component';
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { VacancesComponent } from './home/middle/vacances/vacances.component';
import { AgentComponent } from './home/middle/agent/agent.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { LoadingComponent } from './loading/loading.component';
import { PreloadResolver } from './preload-resolver.service';
import { RedirectorComponent } from './redirector/redirector.component';


export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, environment.api+'filesystem/getfile/languages/', ".json");

}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    MiddleComponent,
    FooterComponent,
    LandpageComponent,
    SearchResultComponent,
    ProductComponent,
    SliderComponent,
    SafePipe,
    HeadmenuComponent,
    MapComponent,
    PageComponent,
    BlogComponent,
    ProductsComponent,
    ServicesComponent,
    ContactFormComponent,
    VacancesComponent,
    AgentComponent,
    NotFoundComponent,
    LoadingComponent,
    RedirectorComponent    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    Angular2UsefulSwiperModule,
    FormsModule,
    HttpClientModule,
    LeafletModule,
    LeafletDrawModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [PreloadResolver,{ provide: Window, useValue: window }],
  bootstrap: [AppComponent]
})
export class AppModule { }
