<ng-container *ngIf="loading">
    <div class="preLoading">
        <app-loading [data]="{  
            hCenter:true,
            vCenter:true,
            width:5,
            bg:true,
            position:'fixed'}" ></app-loading>
    </div>
</ng-container>
<div [class]="!loading ? 'top-menu':''">

    <div class="middle">
        <div class="img">
            <img class="logoWhite" src="assets/images/logo.svg" alt="" srcset="">
            <div class="imgText"> {{('header.title'| translate)}}</div>
        </div>
        <div class="button link" [routerLink]="'/products'">{{('header.knowMore'| translate)}}</div>
      </div>
    <div *ngIf="!loading && false" class="top-menu" [class.transition]="transition" [style.background-image]="'url('+api+'filesystem/getfile/'+slider[counter].img+'/'+imageSizes+')'">

        <ng-container *ngIf="true">
                  <app-search-result [data]="{startfilter:false}"> </app-search-result>
                  <div *ngIf="False" class="yellow-bg link" [routerLink]="'/object/'+slider[counter].linkedObjectID" >
                      <div class="pb-5">
                          <div class=" textinorange">
                          <h4>{{slider[counter].title[language]}}</h4>
                              <p>{{slider[counter].street[language]}} </p>
                              <h2> <span *ngIf="slider[counter] && slider[counter].from" style="font-size: 20px;"> {{('header.filter.from'| translate)}} </span>  {{slider[counter].priceText[language]}}</h2>
                          </div>
                      </div>
                  </div>
          
                  
        </ng-container>
        
      </div>
</div>


<div class="container-fluid">
    <div class="space" *ngIf="true"></div>
<div class="Uppermenu" *ngIf="false">
        <ng-container *ngFor="let link of links ">
                <div class="link dib padd-dis rempad" [routerLink]="['/products']" [queryParams]="{params:JSON.stringify(link.filterOptions)}" [style]=" 'width:'+(100 / links.length)+'%;' | safe:'style'">{{link.title[language] | uppercase}}</div>   

          </ng-container>
</div>
</div><!----->

