import { Component, Input, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/global.service';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {
 @Input('limited') limited:boolean=false


  public contactForm={
    name:'',
    email:'',
    theme:'',
    phone:'',
    message:'',
    date:null
  }
  public loading=false
  public sent=false
  constructor(private global :GlobalService) { }

  ngOnInit() {
  }
  doSomething(task){
    if(task=='sendMail'){
      this.loading=true
      this.contactForm.date=new Date().getTime()
      this.global.HTTPReq('POST',this.global.api,'messages/sendMail',{
        subject: 'ContactForm',
        template: 'contact',
        data: this.contactForm,
      }).then((res)=>{
        if(res.success){
          this.loading=false
          this.sent=true
          this.contactForm={
            name:'',
            email:'',
            theme:'',
            phone:'',
            message:'',
            date:null
          }
        }
      })


    }
  }

}
