import { Component, OnInit, Input } from '@angular/core';
import { GlobalService } from '../global.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {
@Input('data')data={
  hCenter:true,
  vCenter:false,
  width:100,
  bg:false,
  position:'relative'
}
public  loadingSize=this.global.imageSize[this.global.deviceType].loading
  constructor(private global:GlobalService) { }

  ngOnInit() {
  }

}
