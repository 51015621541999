<div class="contForm">
    <div class="inputFields dib" [class.longVersion]="limited">
        <div class="title">{{('contacts.contactForm'| translate)}}</div>
        <div *ngIf="!loading && !sent" class="fields">
            <div class="name dib inpF">
                <input type="text" [(ngModel)]="contactForm.name" [placeholder]="'contacts.name'| translate" class="inpFi">
            </div>
            <div class="mobile dib inpF">
                <input type="text" [(ngModel)]="contactForm.phone" [placeholder]="'contacts.tel'| translate" class="inpFi">
            </div>
            <div class="email dib inpF">
                <input type="text" [(ngModel)]="contactForm.email" [placeholder]="'contacts.email'| translate" class="inpFi">
            </div>
            <div class="theme dib inpF">
                <input type="text" [(ngModel)]="contactForm.theme" [placeholder]="'contacts.theme'| translate" class="inpFi">
            </div>
            <div class="message">
                <textarea [(ngModel)]="contactForm.message" [placeholder]="'contacts.message'| translate" name="" id="" cols="30" rows="5"></textarea>
            </div>
            <div (click)="doSomething('sendMail')" class="sendButt link">{{'contacts.send'| translate}}</div>
        </div>

        <div *ngIf="loading" class="loading">
            <app-loading [data]="{hCenter:true,
                vCenter:true,
                width:10,
                bg:false,
                position:'relative'}" ></app-loading>
        </div>
        <div *ngIf="sent" >
            Nosūtīts!
        </div> 
    </div>
    <div class="contactFields dib" [class.shortVersion]="limited">

        <div class="left dib" *ngIf="!limited">
            <div class="title">{{('contacts.contactInfo'| translate)}}</div>
            <br>M +371 23 999 333
            <br>info@immostate.lv
            <br>
            <div class="title">{{('contacts.location'| translate)}}</div>
            Alberta iela 12-2
            <br>Rīga, LV-1010, Latvija
        </div>
        <div class=" dib" [class.right]="!limited" [class.center]="limited">

            <div class="title">{{('contacts.rekv'| translate)}}</div>
            SIA IMMO REALTY
            <br>Reģ. Nr. 40203179337
            <br>Alberta iela 12-2
            <br>Rīga, LV-1010, Latvija
            <br>Banka AS SWEDBANK
            <br>SWIFT kods: HABALV22
            <br>LV24HABA0551048592511
        </div>
    </div>
</div>