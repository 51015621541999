import { Component, OnInit, HostListener } from '@angular/core';
import { GlobalService } from 'src/app/global.service';
import { MessageService } from 'src/app/message.service';
import { Subscription } from 'rxjs';
import { PreloadResolver } from 'src/app/preload-resolver.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @HostListener('window:scroll', ['$event'])
  scroll(event) {
    if (this.loading) {
      window.scrollTo(0, 0)
    }
  }
  public loading = true
  public preloadFiles = [

  ]
  public imageSizes=this.global.imageSize[this.global.deviceType].slider
  public counter = 0
  public transition=false
  public CounterEnabled = true
  public JSON = JSON
  public api = this.global.api
  public language = this.global.language
  public links = [
    { title: { LV: "Mājas" }, filterOptions: '{"objectType":{"2":true},"paymentType":0,"rooms":{"from":null,"to":null},"price":{"from":null,"to":null},"areaSize":{"from":null,"to":null},"multiplier":0.1,"location":{"1":"Visas","2":"Centrs"},"sort":0}' },
  ]
  public slider = [{
    img: "",
    linkedObjectID: 1,
    priceText: { LV: '' },
    street: { LV: '' },
    title: { LV: '' },
    from: true,
    timer: 10000
  }]
  message: any
  subscription: Subscription
  constructor(private global: GlobalService, private messageService: MessageService, private preloader: PreloadResolver) {
    
    this.global.languageChecker().then(() => {
      this.language = this.global.language
    })
    this.subscription = this.messageService.getMessage().subscribe(message => {
      this.message = message;
      if (this.message['text'] == 'UpdateLang') {
        this.language = this.message['element'];
        //////console.log('maina', this.language)
      }
    });
  }

  async ngOnInit() {
    this.imageSizes=this.global.imageSize[this.global.deviceType].slider
    this.links = await this.global.HTTPReq('GET', this.global.api, 'filesystem/getfile/alighment/menu.json')
    await this.global.HTTPReq('GET', this.global.api, 'filesystem/getfile/alighment/slider.json').then((res) => {
      this.slider = res
      this.addImagesToPreload()
    })
    this.startCounter()
  }

  ngOnDestroy(): void {
    this.CounterEnabled = false
  }

  startCounter() {

    if (this.CounterEnabled) {

      if (this.counter > this.slider.length - 1) {
        this.counter = 0
      }
      ////console.log(this.counter,this.slider.length-1)

      let Timer = 10000
      if (this.slider[this.counter]['timer']) {
        Timer = this.slider[this.counter]['timer']
      }
      setTimeout(() => {
        this.transition=true
      }, Timer-500)
      setTimeout(() => {
        this.counter++
        this.startCounter()

      }, Timer)
    }
  }

  async addImagesToPreload() {
    for (let i = 0; i < this.slider.length; i++) {
      this.preloadFiles.push({ src: this.slider[i].img })
    }
    //console.log(this.preloadFiles)
    //http://localhost:3011/api/filesystem/getfile/bildes-agentiem/1571066380332.png");
    //file.src = this.api+'filesystem/getfile/'+source;
    // "bildes-slaideri/DG_jpg.jpg"
    //url("http://localhost:3011/api/filesystem/getfileFromURL/http%3A%2F%2Fsothebys.ftp.next.ee%2FScoro%2FRealEstate%2F194436%2F4_1_3_7eef0359a523f93996db4aebb94eb99c.png/small2/cover")
    await this.preloader.preload(this.preloadFiles).then(() => {
      this.loading = false
      this.messageService.sendMessage('loadingDone')
    })
  }

}
