<app-headmenu></app-headmenu>

<ng-container *ngIf="product">
    
    <title>{{product && product.name?product.name[langChanger[language]]:''}}</title>
    <meta name="description" [content]="htmlToFormattedText(product.desc[langChanger[language]])" />
</ng-container>


<div class="product" *ngIf="product">
    <section class="gallery">
        <div class="container mt-5">
            <div class="dib bigIMG">
                <div class="orangeFiels">
                    <h5 class="font-open">
                        <span
                            [innerHTML]="product.location.street?product.location.street:'' +' '+(product.location.houseNumber?product.location.houseNumber:'') | safe:'html'"></span>
                        <!-- <br> <span *ngFor="let loc of product.location.levels"> {{loc}}</span> -->
                        <!-- {{product.location.levels[1]}} -->
                        <!-- <br>
                            <span>{{product['location'].street}}</span>
                            <span>{{product['location'].houseNumber}}</span> -->
                        <br>
                        <span> {{product.hierarchy.type=='parent' ? ('products.startingFrom' | translate) : ''}}
                            {{product.hierarchy.type=='parent' ? product.parrentData.lowestChildPrice: product['price']}}
                            EUR</span>


                    </h5>
                </div>
                <div (click)="popup=true"
                    [style.background-image]="'url('+api+'filesystem/getfileFromURL/'+encodeURIComponent(product['img'])+'/'+imageSize[deviceType].popup+'/cover)' | safe:'style'"
                    class="img-big link"></div>

            </div>
            <!-- <div class="col-4 col-md-12 pb-1 pd-l-2 link" (click)="doSomething('jumpToImg',1);popup=true " >
                        <img [style.background-image]="'url('+product.galery[0]+')' | safe:'style'"  class="img-side">
                    </div> -->
            <div class="dib slides">
                <ng-container *ngFor="let img of product.galery; let i = index;">
                    <div (click)="doSomething('jumpToImg',i) "
                        [style.background-image]="'url('+api+'filesystem/getfileFromURL/'+encodeURIComponent(img)+'/'+imageSize[deviceType].slider+'/cover)' | safe:'style'"
                        [class]="i==2 && product.galery.length>2 ? 'img-side link gray':'img-side link'" *ngIf='i<3'>
                    </div>
                    <div class="amount" *ngIf="i==2">+{{product.galery.length-2}}</div>
                </ng-container>


            </div>
        </div>
    </section>
    <!------>

    <!-- [style.display]="popup ? 'initial':'none'" -->
    <div *ngIf="popup" class="closeWhite link" (click)="popup=false">X</div>


    <div class="popupIMG" *ngIf="popup">

        <swiper *ngIf="product.galery && product.galery.length>0" [config]="config" class="swiper-container"
            #usefulSwiper>
            <div class="swiper-wrapper">
                <div class="swiper-slide" *ngFor="let image of product.galery;let i = index;">
                    <div class="imgCont">

                        <img (click)="fullscreenImg=image" class="img-slide"
                            [src]="api+'filesystem/getfileFromURL/'+encodeURIComponent(image)+'/'+imageSize[deviceType].popup+'/contain'"
                            alt="">
                        <div style="display: none;"
                            [style.background-image]="'url('+api+'filesystem/getfileFromURL/'+encodeURIComponent(image)+'/small/contain)' | safe:'style'"
                            class="img-slide"></div>
                    </div>
                </div>

            </div>
            <div class="bgPagna right">
            </div>
            <div class="bgPagna left">
            </div>
            <div class="next link" (click)="doSomething('next')">></div>


            <div class="back link" (click)="doSomething('back')">
                < </div>
        </swiper>



        <div #smallSlide class="smallslide">
            <div class="cont" [style.min-width]="product.galery.length*145+'px'">
                <ng-container *ngFor="let image of product.galery,let i = index;">

                    <div (click)="doSomething('jumpToImg',i) "
                        [style.background-image]="'url('+api+'filesystem/getfileFromURL/'+encodeURIComponent(image)+'/'+imageSize[deviceType].slider+'/cover)' | safe:'style'"
                        [class]=" selectedImg==i ? 'img-slide-small dib selectedIMG' : 'img-slide-small dib'"></div>

                </ng-container>
            </div>
        </div>

    </div>

    <!------Params Menu3 parametres ----->
    <section>
        <div class="container  pt-3 sm-menu">
            <div class="ul-items">

                <!-- only landingSize and areaSize M2 -->
                <ng-container *ngFor="let param of Object.keys(product['size']) ; let i = index;">
                    <div class="dib params" *ngIf=" product['size'][param]>0 && ('params.'+param | translate) !=''">
                        <span class="paramName one">
                            {{Number(product['size'][param]).toFixed(0)}}

                        </span>
                        
                        <span  class="paramName two">
                            <ng-container *ngIf="param=='areasize'" >
                                
                                    M<sup>2</sup>
                                
                               
                            </ng-container>
                            <ng-container *ngIf="param=='landsize'">
                                <span style="font-size: inherit;" [innerHTML]="'params.landSize' | translate"></span>
                            </ng-container>
                        </span>
                    </div>

                </ng-container>



                <ng-container *ngFor="let param of Object.keys(product['params']) ; let i = index;">


                    <ng-container *ngIf="!isObject(product['params'][param]) && param!='visible'">
                        <div class="dib params" *ngIf="('params.'+param | translate) !=''">
                            <!-- param Name -->
                            <!-- (['commercialDining','commercialManufacture','commercialBureau','commercialHostel','commercialTrade','commercialWarehouse'].indexOf(product.type.object[0])!=-1? 'roomsInHome':param) -->
                            <span class="paramName three" *ngIf="param=='numberOfRooms' "
                                [innerHTML]="'params.'+param | translate">
                            </span>
                            <!-- param value -->
                            <span class="paramName two" *ngIf="param!='numberOfRooms'"
                                [innerHTML]="'params.'+param | translate">
                            </span>

                            <!-- param value -->
                            <!-- [style.font-size]="isNumber(product['params'][param])? '20px' : 'initial' " -->
                            <span class="paramName one" *ngIf="product['params'][param]!==true">
                                {{ product['params'][param] }}{{param=='floorNumber'?'.':''}}
                            </span>



                        </div>
                    </ng-container>
                    <ng-container *ngIf="product['params'][param]['LV']">
                        <div class="params dib">
                            <span class="paramName" [innerHTML]="product['params'][param][language]+' '+ param">

                            </span>
                        </div>

                    </ng-container>
                </ng-container>



            </div>
        </div>
    </section>
    <!----->

    <!---- Agentsthree Sections----->
    <section>
        <div class="container">
            <div class="dib prodInfo">
                <h1 style=" font-size: 1.27rem; font-weight: 400;" [innerHTML]="preKey[language]+' - '+ (product.name[langChanger[language]] ? product.name[langChanger[language]]:product.name['LV'])">
                </h1>
                <p *ngIf="product.desc[langChanger[language]]"
                    [innerHTML]="!readmore ? product.desc[langChanger[language]].substr(0,300)+'...' : product.desc[langChanger[language]]">
                </p>

                <p *ngIf="product.desc[langChanger[language]] && product.desc[langChanger[language]].length>300;"
                    (click)="!readmore ? readmore=true : readmore=false" class="readMore link">
                    {{!readmore?'Lasīt vairāk':'Paslēpt'}}</p>
            </div>
            <div class="dib agent">
                <ng-container *ngIf="product.hierarchy.type=='child' && agents[0]">
                    <div class="dib agents" [style.width]="'100%'">
                        <div [routerLink]="'/agent/'+agents[0].id"  class="img dib">
                            <div [style.background-image]="'url('+api+'filesystem/getfile/'+agents[0].otherJSON.img+')' | safe:'style'"
                                class="img-fluid"> </div>
                        </div>
                        <div class="info dib" [style.width]="'50%'">
                            <h4 [routerLink]="'/agent/'+agents[0].id" class="link">{{agents[0].name.toUpperCase()}} <br>{{agents[0].surname.toUpperCase()}}</h4>
                            <p></p>
                            <b>{{agents[0].otherJSON.desc[language]}}</b>
                            <br><br>
                            <h6>
                                <a [href]="'tel:'+agents[0].otherJSON.soc[2].link.split(' ').join('')"></a>
                                {{agents[0].otherJSON.soc[2].link}}
                                <br>
                                {{agents[0].otherJSON.soc[3].link}}

                            </h6>


                            <a *ngFor="let socNetw of agents[0].otherJSON.soc;"
                                [href]="socNetw.title=='fb' ? 'https://m.me/'+socNetw.link : socNetw.title=='wpp' ? 'https://api.whatsapp.com/send?phone=+371'+socNetw.link : socNetw.title=='tel' ? 'tel:'+socNetw.link : socNetw.title=='email' ? 'mailto:'+socNetw.link : ''">
                                <img [src]="'assets/img/contact/'+socNetw.title+'.svg'" class="icons mr-3"></a>



                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="product.hierarchy.type=='parent'">
                    <ng-container *ngFor="let agent of agents;let i = index;">
                        <div class="dib agents" [routerLink]="'/agent/'+agent.id" [style.width]="(agents.length>1?100/2:100)+'%'">
                            <div *ngIf="!(agents.length>1)" class="img dib">
                                <div [routerLink]="'/agent/'+agent.id"  [style.background-image]="'url('+api+'filesystem/getfile/'+agent.otherJSON.img+')' | safe:'style'"
                                    class="img-fluid"> </div>
                            </div>
                            <div class="info dib" [style.width]="!(agents.length>1)? '50%':'100%'">
                                <h4 [routerLink]="'/agent/'+agent.id" class="link"  >{{agent.name.toUpperCase()}} <br>{{agent.surname.toUpperCase()}}</h4>
                                <p></p>
                                <b>{{agent.otherJSON.desc[language]}}</b>
                                <br><br>
                                <div class="agentContact">
                                    <a [href]="'tel:'+agent.otherJSON.soc[2].link.split(' ').join('')"></a>
                                    {{agent.otherJSON.soc[2].link}}
                                    <br>
                                    {{agent.otherJSON.soc[3].link}}
                                </div>
                              


                                <a *ngFor="let socNetw of agent.otherJSON.soc;"
                                    [href]="socNetw.title=='fb' ? 'https://m.me/'+socNetw.link : socNetw.title=='wpp' ? 'https://api.whatsapp.com/send?phone=+371'+socNetw.link : socNetw.title=='tel' ? 'tel:'+socNetw.link : socNetw.title=='email' ? 'mailto:'+socNetw.link : ''">
                                    <img [src]="'assets/img/contact/'+socNetw.title+'.svg'" class="icons mr-3"></a>


                                <!-- <button class="blue-btn1 mt-2">Nosūtīt pieteikumu</button> -->

                            </div>
                        </div>
                    </ng-container>



                </ng-container>
            </div>
            <div class="map dib">
                <app-map *ngIf="product"
                    [data]="{markers:markers,zoom:14,centre:mapView,width:mapSettings.width,height:mapSettings.height}">
                </app-map>

            </div>

        </div>
    </section>
    <!------>

    <section *ngIf="false">
        <div class="productDesc">
            <div class="prodText dib">
                <h5 [innerHTML]="product.name[langChanger[language]] ? product.name[langChanger[language]]:product.name['LV']"></h5>
                <p *ngIf="product.desc[langChanger[language]]"
                    [innerHTML]="!readmore ? product.desc[langChanger[language]].substr(0,300)+'...' : product.desc[langChanger[language]]">
                </p>

                <p *ngIf="product.desc[langChanger[language]].length>300;"
                    (click)="!readmore ? readmore=true : readmore=false" class="readMore link">
                    {{!readmore?'Lasīt vairāk':'Paslēpt'}}</p>
            </div>
            <div class="agentInfo dib">
                <div>
                    <div *ngFor="let agent of agents;" class="dib agents"
                        [style.width]="this.deviceType=='pc'?  100/agents.length+'%' :'100%'">
                        <div *ngIf="!(agents.length>1)" class="img dib">
                            <div [style.background-image]="'url('+api+'filesystem/getfile/'+agent.otherJSON.img+')' | safe:'style'"
                                class="img-fluid"> </div>
                        </div>
                        <!-- <div class="info dib" [style.width]="!(agents.length>1 )? '50%':'100%'" >
                            <h3>{{agent.name}} {{agent.surname}}</h3><p></p>
                            {{agent.otherJSON.desc[language]}}
                            <h6>
                              <a [href]="'tel:'+agent.otherJSON.soc[2].link.split(' ').join('')" ></a>
                              {{agent.otherJSON.soc[2].link}}

                            </h6><p></p>
                            <h6><a [href]="'mailTo://'+agent.otherJSON.soc[3].link">
                              {{agent.otherJSON.soc[3].link}}
                            </a> </h6><p></p>


                            <a *ngFor="let socNetw of agent.otherJSON.soc;" [href]="socNetw.title=='fb' ? 'https://m.me/'+socNetw.link : socNetw.title=='wpp' ? 'https://api.whatsapp.com/send?phone=+371'+socNetw.link : socNetw.title=='tel' ? 'tel:'+socNetw.link : socNetw.title=='email' ? 'mailto:'+socNetw.link : ''" >
                                <img [src]="socNetw.icon" class="icons mr-3"></a>

                        </div> -->
                    </div>

                </div>
            </div>
            <div class="map dib">
                <app-map
                    [data]="{markers:markers,zoom:14,centre:mapView,width:mapSettings.width,height:mapSettings.height}">
                </app-map>
            </div>
        </div>
    </section>



</div>
<div *ngIf="fullscreenImg"
    style="position: fixed; width: 100%; height:100%;top:0;left:0;z-index: 10;background: black;">
    <div class="imgFullscreen" (click)="fullscreenImg=''"
        [style.background-image]="'url('+api+'filesystem/getfileFromURL/'+encodeURIComponent(fullscreenImg)+'/'+imageSize[deviceType].popup+'/contain)' | safe:'style'">
    </div>
</div>

<app-slider
    *ngIf="product && product.parrentData && product.parrentData.children && product.parrentData.children.length>0"
    [data]="{title:{LV:'Iekļauj Objektus',RU:'Bключает в себя объекты',EN:'Include Objects'}, type:'object',children:product.parrentData.children, hideShowMore:true}">
</app-slider>
<app-slider *ngIf="filterOptions.active"
    [data]="{title:{LV:'Jums varētu interesēt',RU:'Вы также можете быть заинтересованы в',EN:'You may be interested in'}, type:'object',filterOptions:filterOptions}">
</app-slider>