export enum objectTypes{
    Dzīvokļi= 0,
    Zeme=1,
    Mājas= 2,
    Jaunais_Projekts=3,
    Komercīpašumi=4,
    Namīpašumi=5,
    Visi=10,
}
export interface objectType{
    [objectTypes.Dzīvokļi]?:boolean,
    [objectTypes.Jaunais_Projekts]?:boolean,
    [objectTypes.Komercīpašumi]?:boolean,
    [objectTypes.Mājas]?:boolean,
    [objectTypes.Namīpašumi]?:boolean,
    [objectTypes.Visi]?:boolean,
    [objectTypes.Zeme]?:boolean,
}
export enum paymentTypes{
    SELL=1,
    RENT=2,
    BOTH=3
}
export interface scaleFilter{
    from: null|number,
    to: null|number
}
export interface location{
    street?: string|number,
    1?: string|number,
    2?: string|number,
}




export interface filter{
    objectType: objectType,
    excludeChildren:{
      [objectTypes.Komercīpašumi]:any,
      [objectTypes.Zeme]:any
    },
    paymentType: paymentTypes,
    rooms:scaleFilter,
    price: scaleFilter,
    areaSize:scaleFilter,
    multiplier: number,
    location?:location,
    sort: 0|1|2|3|4|5|6,
    
  }

  export enum alignmentTypes {
    OBJECT='object',
    CONTACTS='contacts',
    BLOG='blogPosts',
    SERVICE='services',
    CONTACTFORM='contactForm',
    VACANCES='vacances',
   }
  export interface alignment {
     categoryID: number
     desc: string
     filterOptions: filter
     slider: boolean
     socLinks: boolean
     title: languages
     type: alignmentTypes
   }
   export interface languages{
    LV?: string,
     EN?: string,
      RU?: string
   }
   export interface scelet {
    categoryID: number
    desc: string
    filterOptions:filter
    slider: boolean
    title: languages
    type: alignmentTypes
    socLinks:any
  }
  export enum devices{
    MOBILE='mobile',
    TABLET='tablet',
    PC='pc'
  }
  export enum imageSizes{
    ICON="icon",
    SMALL='mobile',
    MEDIUM='medium',
    LARGE='large',
    HUGE="huge"
  }
  export enum imageTypes{
    QUESTION='questionImg',
    TESTIMG='testIMG',
    POPUP='popup',
    IMGCARD='itemCard'
  }