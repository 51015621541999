import { Component, OnInit, Input } from '@angular/core';
import { GlobalService } from 'src/app/global.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {
  @Input('data') data: any[]
  public language = this.global.language
  public productList = []

  public counter = 0;
  public CounterEnabled = false

  public api = this.global.api

  public service = {
    categoryID: 0,
    desc: "",
    filterOptions: {},
    slider: true,
    title: { LV: "Pakalpojumi", EN: "Services", RU:"Сервисы" },
    type: "services"
  }

  constructor(private global: GlobalService, private route: ActivatedRoute) {
    this.route.paramMap
      .subscribe(params => {
        setTimeout(() => {
          //////console.log(params['params'][params.keys[0]])
          let slideTitle = params['params'][params.keys[0]];
          if (slideTitle) {
            ////console.log(this.productList)
            for (let i = 0; i < this.productList.length; i++) {

              if (this.productList[i].title[this.language] == slideTitle) {
                this.CounterEnabled = false
                this.counter = i
                return
              } else {
                this.CounterEnabled = true
              }

            }
          } else {
            this.CounterEnabled = true
          }
          this.startCounter()

        }, 500)

      })
  }

  async ngOnInit() {
    this.productList = await this.global.HTTPReq('GET', this.global.api, 'filesystem/getfile/alighment/pakalpojumi.json')
    this.startCounter()
  }
  ngOnDestroy(): void {
    this.CounterEnabled = false
  }

  startCounter() {

    if (this.CounterEnabled) {

      if (this.counter > this.productList.length - 1) {
        this.counter = 0
      }
      ////console.log(this.counter, this.productList.length - 1)

      let Timer = 10000
      if (this.productList[this.counter]['timer']) {
        Timer = this.productList[this.counter]['timer']
      }
      setTimeout(() => {
        this.counter++
        this.startCounter()

      }, Timer)
    }
  }

}
