import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from '../global.service';
import { MessageService } from '../message.service';
export interface  page{
  id: number,
  type: number,
  error?:boolean,
  success?:boolean,
}
@Component({
  selector: 'app-redirector',
  templateUrl: './redirector.component.html',
  styleUrls: ['./redirector.component.sass']
})
export class RedirectorComponent implements OnInit {

  public page:page={
    id: null,
    type: null,
    error:false,
    success:false
  }
  subscription: Subscription;
  constructor(private route: ActivatedRoute,private global: GlobalService,private router:Router, private messageService: MessageService) {

    this.subscription = this.messageService.getMessage().subscribe(message => {
      
      if (message['text'] == 'changePage') {
        this.checkPageRoute()
      }
    });
    
   }

 async ngOnInit() {

  this.checkPageRoute()
        
       
  }

  async checkPageRoute(){
    this.page.error=false
    this.page.success=false
    let link= await this.route.pathFromRoot[1].url['value'][0].path
    console.log(link)
    if(link){
    let page:page= await this.global.HTTPReq('POST',this.global.api,'pages/getPageID',{ link: link })
   console.log(page)
   if(!page){
    this.router.navigateByUrl('/')
   }
    if(!page.success){
      this.page.error=true
      this.router.navigateByUrl('/')
      return
    }
    if(page && page.id){
       this.page=page
       //this.router.navigateByUrl(`/page/${page.id}b${page.type}/`+link)
       //redirect
       return
     }
    }
  }


}
